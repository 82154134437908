import styled from "@emotion/styled";
import { Grid } from "@mui/material";
import { FC } from "react";

interface Image {
  name: string;
  url: string;
}

interface IImagePreviewerProps {
  images: Image[];
}

const ImagePreviewer: FC<IImagePreviewerProps> = ({ images }) => {
  return (
    <Container>
      <Grid container spacing={2}>
        {images.map((image: any) => (
          <Grid item xs={6} sm={2} key={image.name}>
            <Thumbnail src={URL.createObjectURL(image)} alt={image.name} />
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default ImagePreviewer;

const Container = styled.div`
  padding: 8px;
  height: 200px;
  box-sizing: border-box;
  overflow-x: scroll;
`;

const Thumbnail = styled.img`
  width: 90%;
  height: 50px;
  cursor: pointer;
  margin-top: 14px;
  border-radius: 4px;
  margin-right: 12px;
`;
