import { FC } from "react";
import styled from "@emotion/styled";
// @ts-ignore
import LazyLoad from "react-lazyload";

import Theme from "../constants/theme";
import { Checkbox } from "@mui/material";
import PriceBanner from "./PriceBanner";

type ImageContainerProps = {
  border?: string;
  selected?: boolean;
};

type TextProps = {
  position?: string;
};

interface IAvatarsForCardProps {
  item?: any;
  selected?: boolean;
  checkbox?: boolean;
  labelPosition?: string;
  showPriceBanner: boolean;
  handleClick?: () => void;
}

const AvatarsForCard: FC<IAvatarsForCardProps> = ({
  item,
  selected,
  checkbox,
  labelPosition = "top",
  showPriceBanner,
  handleClick,
}) => {
  return (
    <ImageContainer
      selected={selected}
      border={`4px solid ${
        selected ? Theme.COLORS.IRIS_BLUE : Theme.COLORS.ONYX
      }`}
      onClick={handleClick}
    >
      {showPriceBanner && <PriceBanner price={item?.price || 59} />}
      <LazyLoad width={208} height={262}>
        <Image src={item?.image} alt={item?.name} />
      </LazyLoad>
      {checkbox && (
        <Checkbox
          aria-label="checkbox"
          checked={!!item?.isSelected}
          sx={{
            height: 24,
            width: 24,
            position: "absolute",
            right: 4,
            top: 4,
            color: Theme.COLORS.TEXT_SECONDARY_COLOR,
            "&.Mui-checked": {
              color: Theme.COLORS.IRIS_BLUE,
            },
          }}
          onChange={(e) => e.stopPropagation()}
        />
      )}
      <Text position={labelPosition}>{item?.name}</Text>
    </ImageContainer>
  );
};

export default AvatarsForCard;

const ImageContainer = styled.div<ImageContainerProps>`
  margin: 16px;
  width: 208px;
  height: 262px;
  cursor: pointer;
  // overflow: hidden;
  border-radius: 12px;
  box-sizing: border-box;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  border: ${(props) => props.border || "none"};
  background: ${Theme.COLORS.BLACK_RUSSIAN};
  opacity: ${(props) => (props.selected ? 1 : 0.75)};
  position: relative;
`;

const Image = styled.img`
  width: 100%;
  height: 254px;
  flex-shrink: 0;
  border-radius: 8px;
  background-size: cover;
  object-fit: cover;
`;

const Text = styled.p<TextProps>`
  position: absolute;
  left: 8px;
  color: ${Theme.COLORS.TEXT_SECONDARY_COLOR};
  font-weight: bold;
  width: fit-content;
  top: ${(props) => (props.position === "top" ? 0 : null)};
  bottom: ${(props) => (props.position === "bottom" ? 0 : null)};
`;
