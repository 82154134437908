import { useState } from "react";
import JSZip from "jszip";

import useUploadZipFileToS3 from "./useUploadZipFileToS3";

interface ImageFile extends File {
  name: string;
}

interface UseImageZipper {
  zipFile: Blob | null;
  createZipFile: (imageFiles: ImageFile[], txnId: string) => void;
}

const useImageZipper = (): UseImageZipper => {
  const { uploadZipFileToS3 } = useUploadZipFileToS3();
  const [zipFile, setZipFile] = useState<Blob | null>(null);

  const createZipFile = (imageFiles: ImageFile[], txnId: string) => {
    const zip = new JSZip();
    const promises: Promise<void>[] = [];

    imageFiles.forEach((file, index) => {
      const fileName = `image_${index + 1}.${file.type.split("/")[1]}`;
      promises.push(
        new Promise<void>((resolve, reject) => {
          zip.file(fileName, file);
          resolve();
        })
      );
    });

    Promise.all(promises)
      .then(() => zip.generateAsync({ type: "blob" }))
      .then((blob) => {
        setZipFile(blob);
        uploadZipFileToS3(blob, txnId);
      })
      .catch((error) => {
        console.error("Error creating zip file:", error);
      });
  };

  return { zipFile, createZipFile };
};

export default useImageZipper;
