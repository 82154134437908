import { Box } from "@mui/material";
import { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { Routes, Route, useLocation } from "react-router-dom";

import Home from "./screens/Home";
import Login from "./screens/Login";
import MyOrders from "./screens/MyOrders";
import Onboarding from "./screens/Onboarding";
import PrivacyPolicy from "./screens/PrivacyPolicy";
import TermsAndConditions from "./screens/TermsAndConditions";
import SideDrawer from "../components/SideDrawer";

import { auth } from "../hooks/useGoogleSignIn";

const DesktopRouter = () => {
  const location = useLocation();
  const [user] = useAuthState(auth);

  const [showSideDrawer, setShowSideDrawer] = useState(false);

  useEffect(() => {
    const routesWithDrawer = ["/home", "/my-orders"];
    setShowSideDrawer(routesWithDrawer.includes(location.pathname));
  }, [location.pathname]);

  return (
    <>
      {showSideDrawer ? <SideDrawer user={user} /> : null}
      <Box
        width={showSideDrawer ? "calc(100% - 220px)" : "100%"}
        sx={{
          position: "relative",
          left: showSideDrawer ? 220 : 0,
        }}
      >
        <Routes>
          <Route path="/" element={<Onboarding />} />
          <Route path="/login" element={<Login />} />
          <Route path="/home" element={<Home user={user} />} />
          <Route path="/my-orders" element={<MyOrders user={user} />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route
            path="/terms-and-conditions"
            element={<TermsAndConditions />}
          />
        </Routes>
      </Box>
    </>
  );
};

export default DesktopRouter;
