import { FC, useEffect } from "react";
import styled from "@emotion/styled";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import Header from "../../components/Header";
import Snackbar from "../../components/Snackbar";
import Carousel from "../../components/Carousel";
import MediaCard from "../../components/MediaCard";
import BottomButton from "../../components/BottomButton";
import SignInDropdownModal from "../../components/SignInDropdownModal";

import usePhonepe from "../../hooks/usePhonepe";
import useSnackbar from "../../hooks/useSnackbar";
import useGoogleAnalytics from "../../hooks/useGoogleAnalytics";
import { setOpenSignInModal } from "../../context/slices/app.slice";
import { memoizedSelectApp } from "../../context/selectors/app.selector";

import Theme from "../../constants/theme";
import { calculateTotalPrice } from "../../utils/price.utils";

interface IPurchaseProps {
  user: any;
}

const Purchase: FC<IPurchaseProps> = ({ user }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {
    avatarsFor,
    loggedInUser,
    selectedStyles,
    showSnackbar,
    snackbarMessage,
    snackbarSeverity,
    openSignInModal,
  } = useSelector(memoizedSelectApp);

  const { disabledBuyNow, paymentInitiated, makePayment, setDisableBuyNow } =
    usePhonepe();

  const { closeSnackbar } = useSnackbar();

  const { sendPageView, sendEvent } = useGoogleAnalytics();

  const handleSignInModal = () => {
    const eventName = "sign_in_modal";
    const properties = {
      source: "mobile",
      action: "click",
      user: loggedInUser?.uid,
      value: !openSignInModal ? "open" : "close",
    };
    sendEvent(eventName, properties);
    dispatch(setOpenSignInModal(!openSignInModal));
  };

  const handlePurchase = () => {
    if (loggedInUser?.idToken) {
      const eventName = "place_order_cta";
      const properties = {
        source: "mobile",
        action: "click",
        user: loggedInUser?.uid,
      };
      sendEvent(eventName, properties);
      setDisableBuyNow(true);
      const payload = {
        price: calculateTotalPrice(selectedStyles) || 295,
        uid: loggedInUser?.uid,
        email: loggedInUser?.email,
        name: loggedInUser?.name,
        avatarsFor: avatarsFor?.name.toLowerCase(),
        selectedStyles,
      };
      makePayment(payload, loggedInUser?.idToken);
    } else {
      dispatch(setOpenSignInModal(true));
    }
  };

  useEffect(() => {
    if (avatarsFor?.name === "") {
      navigate("/home");
    } else {
      const properties = {
        action: "pageView",
        page: "checkout",
        source: "mobile",
        user: user?.uid,
      };
      sendPageView(properties);
    }
  }, [user]);

  return (
    <Container>
      <Header profile={false} title="Checkout" />
      <Carousel />
      <SkuDetails>
        <MediaCard avatarsFor={avatarsFor} selectedStyles={selectedStyles} />
      </SkuDetails>
      <BottomButton
        disabled={selectedStyles?.length < 5 || disabledBuyNow}
        paymentInitiated={paymentInitiated}
        text="Place Order"
        onClick={handlePurchase}
      />
      <SignInDropdownModal
        open={!loggedInUser?.idToken && openSignInModal}
        modalTitle="Sign In"
        handleClose={handleSignInModal}
      />
      <Snackbar
        open={showSnackbar}
        vertical="bottom"
        horizontal="center"
        message={snackbarMessage}
        //@ts-ignore
        severity={snackbarSeverity}
        handleClose={closeSnackbar}
      />
    </Container>
  );
};

export default Purchase;

const Container = styled.div`
  width: 100%;
  display: flex;
  height: calc(100vh - 142px);
  flex-direction: column;
  background: ${Theme.COLORS.BG_COLOR};
  overflow: auto;
`;

const SkuDetails = styled.div`
  max-width: 768px;
  width: 100%;
  box-sizing: border-box;
  padding: 0 8px;
`;
