import { Box } from "@mui/material";
import Theme from "../../constants/theme";

const PrivacyPolicy = () => {
  return (
    <Box
      p={4}
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      minHeight="100vh"
    >
      <Box
        sx={{
          color: Theme.COLORS.TEXT_SECONDARY_COLOR,
          lineHeight: 2,
          textAlign: "justify",
          width: "100%",
          maxWidth: "800px",
        }}
      >
        <header style={{ textAlign: "center" }}>
          <h1>Privacy Policy</h1>
        </header>
        <h2>Who we are</h2>
        <p>Our website address is: https://lensifyai.com.</p>

        <p>Effective date: April 15, 2024</p>

        <p>
          Welcome to Lensify AI. (“<strong>Company</strong>,” “
          <strong>we</strong>
          ,” “<strong>us</strong>” or “<strong>our</strong>
          ”), an AI technology firm specializing in deep learning-related
          products. Our goal is to move forward mobile photography and video
          creation to the next level using neural networks, deep learning and
          computer vision technics. We aim to create new ways for people to
          express their emotions through the camera.
        </p>

        <p>
          This Privacy Policy (this “<strong>Privacy Policy</strong>”)
          describes:
        </p>

        <ul>
          <li>
            The types of information we may collect or that all users or others
            may provide while downloading, installing, registering with,
            accessing, or otherwise using (“<strong>Users</strong>”, “
            <strong>you</strong>” or “<strong>your</strong>”, as applicable) our
            application Lensify AI (“
            <strong>Lensify AI</strong>” or “<strong>application</strong>”).
          </li>

          <li>
            Our practices for collecting, using, maintaining, protecting, and
            disclosing that information.
          </li>
        </ul>

        <p>
          This Privacy Policy applies only to information we collect in Lensify
          AI and via email, text, and other electronic communications sent
          through or in connection with Lensify AI.
        </p>

        <p>
          We undertake to build Lensify AI and any of its features in such a way
          that it respects your privacy and are committed to protecting it
          through our compliance with this Privacy Policy. This Privacy Policy
          not only allows you to understand what we do with your Personal Data
          (as defined below), but also to manage your Personal Data effectively.
        </p>

        <p>
          <strong>
            Please read this Privacy Policy carefully to understand our policies
            and practices regarding your information and how we will treat it.
            By
          </strong>
          <strong>
            downloading, installing, registering with, accessing or using
            (“Using”) Lensify AI, you agree to this Privacy Policy. If you do
            not agree to this Privacy Policy, do not Use Lensify AI.
          </strong>
        </p>

        <p>
          By Using Lensify AI you understand and agree that we are providing a
          platform for you to stylize photos. If you have any questions about
          this Privacy Policy or Lensify AI, please contact us (for contact
          information, please, see How to Contact Us Section).
        </p>

        <p>This Privacy Policy DOES NOT apply to the information that:</p>

        <ul>
          <li>
            We collect offline or on any other our apps or websites, including
            websites you may access through Lensify AI.
          </li>

          <li>
            You provide to or is collected by any third party, including through
            any other application or content that may link to or be accessible
            through Lensify AI.
          </li>
        </ul>

        <p>
          Our websites and other apps, and these other third parties may have
          their own privacy policies, which we encourage you to read before
          providing information on or through them.
        </p>

        <p>
          This Privacy Policy may change from time to time (see Changes to Our
          Privacy Policy Section). Your continued use of Lensify AI after we
          make changes is deemed to be acceptance of those changes, so please
          check this Privacy Policy periodically for updates.
        </p>

        <h3>
          <strong>1. INFORMATION WE COLLECT AND HOW WE COLLECT IT</strong>
        </h3>

        <p>
          We may collect several types of information, including but not limited
          to Personal Data, from and about you:
        </p>

        <ul>
          <li>Directly from you when you provide it to us.</li>

          <li>
            Automatically when you Use Lensify AI. Information collected
            automatically may include Usage details, internet protocol (“
            <strong>IP</strong>”) addresses and information collected through
            cookies and other tracking technologies.
          </li>

          <li>
            From third parties, for example, our Service Providers (as defined
            below).
          </li>
        </ul>

        <p>
          To the extent information is: (i) associated with an identified or
          identifiable natural person and (ii) protected as personal data under
          applicable data protection laws, such information is referred to in
          this Privacy Policy as “<strong>Personal Data</strong>”. Please bear
          in mind that provisions of Section 5 do not apply to your
          pseudonymized Personal Data.
        </p>

        <h3>
          <strong>2. INFORMATION YOU PROVIDE TO US DIRECTLY</strong>
        </h3>

        <p>
          When you Use Lensify AI, we may ask you to provide certain
          information, including your Personal Data:
        </p>

        <ul>
          <li>
            Photos that you provide when you Use Lensiify, via your camera or
            camera roll (if you have granted us permission to access your camera
            or camera roll). You may revoke permission to access your camera or
            camera roll through the settings on your mobile device. Please note
            that while we do not require or request any metadata attached to the
            photos you upload, metadata (including, for example, geotags) may be
            associated with your photos by default.
          </li>

          <li>
            Information that you provide by interacting with Lensify AI. This
            includes information provided at the time of Using Lensify AI,
            purchasing certain enhanced features of Lensify AI, requesting
            further services through Lensify AI and filling in forms in Lensify
            AI. We may also ask you for information when you report a problem
            with Lensify AI.
          </li>

          <li>Details of your in-app subscriptions in Lensify AI.</li>

          <li>
            If you contact us, records, copies of your correspondence with us
            and contact details that you have provided us while making your
            inquiries (such as your name, postal addresses, email addresses and
            phone numbers or any other identifier by which you may be contacted
            online or offline), in order to investigate your concerns, respond
            to your inquiries and to monitor and improve our responses to your
            and other Users inquiries in relation to Lensify AI.
          </li>
        </ul>

        <p>
          You may provide photos (with or without applied Lensify AI stylized
          filters or effects) for publication or display on third-party websites
          and other online services you access through Lensify AI (“
          <strong>Post</strong>”). You confirm that you have all required
          rights, approvals and consents from any third parties, if applicable,
          to use their photos or photos on which other individuals are shown
          through Lensify AI and to Post such photos on third-party websites and
          other online services. This may be applicable if you: (i) Post, or use
          photos (via Lensify AI) that are not your’s; or (ii) Post, or use
          photos (via Lensify AI) that show other individuals, that require such
          persons’ and/or individuals’ consent (for more information on the
          Lensify AI terms of use, please visit{" "}
          <a href="/terms-and-conditions">the following link</a>
          ).
        </p>

        <p>
          Your photos are Posted and transmitted to others at your own risk.
          Please be aware that no security measures are perfect or impenetrable.
          Additionally, we cannot control the actions of third parties with whom
          you may choose to share your photos. Therefore, we cannot and do not
          guarantee that your photos will not be viewed by unauthorized persons.
        </p>

        <h3>
          <strong>
            3. INFORMATION WE COLLECT THROUGH AUTOMATIC DATA COLLECTION
            TECHNOLOGIES
          </strong>
        </h3>

        <p>
          When you Use Lensify AI, we may use automatic data collection
          technologies to collect certain information about your equipment,
          browsing actions and patterns, including:
        </p>

        <ul>
          <li>
            <strong>Usage details</strong>. Details of your Use of Lensify AI,
            including traffic data, logs and other communication data and
            resources you use through Lensify AI.
          </li>

          <li>
            <strong>Device information</strong>. Information about your mobile
            device and internet connection, including your IP address, the
            device’s unique device identifier, operating system, browser type
            and mobile network information.
          </li>

          <li>
            <strong>Stored information and files</strong>. Please note that
            while we do not require or request any metadata attached to the
            photos you upload to Lensify AI, metadata (including, for example,
            geotags) may be associated with your photos by default.
          </li>
        </ul>

        <p>
          We also may use these technologies to collect information about your
          online activities over time and across third-party websites or other
          online services (behavioral tracking). If you do not want us to
          collect this information, do not download Lensify AI or delete it from
          your device.
        </p>

        <p>
          The information we collect automatically under this Privacy Policy may
          include your Personal Data and information we may maintain or
          associate with your Personal Data that we collect in other ways or
          receive from third parties. It helps us to improve Lensify AI and to
          deliver a better and more personalized service, including but not
          limited to enabling us to estimate our audience size and Usage
          patterns and recognize when you Use Lensify AI.
        </p>

        <p>
          If the information covered by this Section 3 is aggregated or
          de-identified so it is no longer reasonably associated with an
          identified or identifiable natural person, we may use it for any
          business purpose.
        </p>

        <p>
          The technologies we use for automatic data collection may include:
        </p>

        <p>
          <strong>Third-party analytics</strong>. We use third-party analytics
          tools, such as Google Firebase, Facebook Analytics, AppsFlyer and
          Amplitude, to help us measure traffic and usage trends for Lensify AI.
          These tools collect information sent by your device or Lensify AI,
          including the web pages you visit when following the links available
          to you on Lensify AI. We collect and use this analytics information
          with analytics information from other Users so that it cannot
          reasonably be used to identify any particular individual User. For
          information on how third-party analytics tools collect and process
          your data and to opt out of such collection and processing, generated
          by your Use of Lensify AI, please visit the following link.
        </p>

        <p>
          <strong>Cookies (or mobile cookies) and similar technologies</strong>.
          A cookie is a small file placed on your smartphone. When you Use
          Lensify AI, we may use cookies and similar technologies to collect
          information about how you Use Lensify AI and provide features to you.
          We may ask our Service Providers to serve services to your devices,
          which may include cookies or similar technologies placed by us or such
          Service Providers (on our request). You may refuse to accept mobile
          cookies by activating the appropriate mobile browser setting on your
          smartphone. You may also block, delete or disable saving cookies on
          your device. You can turn the cookies off by choosing the appropriate
          settings of your browser. The following links might be useful for you
          to choose the best option of your browser:
        </p>

        <p>For the users of Chrome on Android and iOS.</p>

        <p>For the users of Safari on iOS.</p>

        <p>
          However, if you select these mobile browser settings you may be unable
          to access certain parts of Lensify AI. Unless you have adjusted your
          mobile browser setting, our system will issue cookies when you direct
          your browser to Lensify AI.
        </p>

        <p>
          <strong>Log file information</strong>. Log file information is
          automatically reported by your mobile browser each time you make a
          request to Use Lensify AI. When you Use Lensify AI, we and our Service
          Providers may automatically record certain log file information,
          including your web request, IP address, browser type, referring/exit
          pages and URLs, number of clicks and how you interact with links on
          and through Lensify AI, domain names, landing pages, pages viewed, and
          other such information. The information allows us to receive more
          accurate reporting and improve Lensify AI.
        </p>

        <p>
          <strong>Device identifiers</strong>. When you use a mobile device like
          a tablet or phone to Use Lensify AI, we may access, collect, monitor,
          store on your device, and/or remotely store one or more “
          <strong>device identifiers</strong>”. Device identifiers are small
          data files or similar data structures stored on or associated with
          your mobile device, which uniquely identify your mobile device. A
          device identifier may identify data stored in connection with the
          device hardware, operating system or other software, or data sent to
          the device by us. A device identifier may deliver information to us or
          to our Service Providers about how you Use Lensify AI and may help us
          and others (on our request) produce a report on such Use.
        </p>

        <p>
          Some features of Lensify AI may not function properly if use or
          availability of device identifiers is impaired or disabled. Among
          others we collect the following device identifiers:
        </p>

        <ul>
          <li>User device ID.</li>

          <li>Platform (iOS/Android).</li>

          <li>Device OS version.</li>

          <li>App version.</li>

          <li>First request time.</li>

          <li>Language.</li>

          <li>Model.</li>

          <li>Push token (optional).</li>
        </ul>

        <p>
          <strong>Metadata</strong>. Metadata is technical data that is
          associated with your photos. For example, metadata may describe when
          and on which device your photo has been taken and how such photo is
          formatted. You may add or may have metadata added to your photos.
          Please note that metadata associated with your photos is deleted
          automatically with your photos within 24 hours after we apply styles
          to them.
        </p>

        <p>
          <strong>Registered Users</strong>. If you are a registered User, we
          may collect more Personal Data about you including the following:
        </p>

        <ul>
          <li>Email.</li>

          <li>User device ID (generated by our application).</li>

          <li>First User authorization date and time.</li>

          <li>
            Google Play store account Information (UserID, nickname, profile
            image), if you choose to log in via your Google Play account.
          </li>

          <li>
            Apple App store account information (Apple ID, profile image), if
            you choose to log in via Sign in with Apple.
          </li>
        </ul>

        <h3>
          <strong>4. HOW WE USE COLLECTED INFORMATION</strong>
        </h3>

        <p>
          <strong>Your photos</strong>. We do not use photos you provide when
          you Use Lensify AI for any reason other than to apply different
          stylized filters or effects to them, unless you have given us your
          explicit consent to use the photos for a different purpose.
        </p>

        <p>
          Please note that to apply different stylized filters or effects to
          your photos we may upload such photos to the cloud providers we use
          (Google Cloud Platform and Amazon Web Services), in which case, the
          photos: (i) become available to us anonymously and (ii) are
          automatically deleted within 24 hours after being processed by Lensify
          AI, unless you have given us your explicit consent to use the photos
          for a different purpose.
        </p>

        <p>
          <strong>Other information</strong>. We may use your information other
          than photos for the following purposes:
        </p>

        <ul>
          <li>
            To provide personalized content and information to you and others in
            relation to Lensify AI, which could include online ads or other
            forms of marketing (including via email).
          </li>

          <li>
            To present to you and others with Lensify AI, its contents and any
            other information, products or services that you request from us.
          </li>

          <li>To automatically update Lensify AI on your device.</li>

          <li>
            To carry out our obligations and enforce our rights arising from any
            contracts entered into between you and us in relation to Lensify AI.
          </li>

          <li>
            To provide you with support and to respond to your inquiries. If you
            share your Personal Data (including your name and contact
            information) to ask a question about Lensify AI, we will use that
            Personal Data to investigate your concerns, respond to your
            inquiries and to monitor and improve our responses to your and other
            Users inquiries in relation to Lensify AI.
          </li>

          <li>
            To notify you about changes to Lensify AI or any products or
            services we offer or provide through Lensify AI, including by
            sending you technical notices, notices about your
            account/subscription, including expiration and renewal notices,
            updates, security alerts and support and administrative messages,
            which we may send through an in-app or a push notification (you may
            opt out of push notifications by changing the settings on your
            mobile device).
          </li>

          <li>
            To diagnose or fix technology problems in relation to Lensify AI.
          </li>

          <li>
            To link or combine with information we get from others or (and) from
            you to help understand your needs and provide you with better
            services.
          </li>

          <li>
            To monitor metrics such as total number of Users, traffic, and
            demographic patterns (including via Service Providers as specified
            in Section 6).
          </li>

          <li>
            To provide, improve, test, and monitor the effectiveness of Lensify
            AI.
          </li>

          <li>To develop and test new products and features.</li>

          <li>To fulfill any other purpose for which you provide it.</li>

          <li>In any other way, we may describe in this Privacy Policy.</li>

          <li>For any other purpose with your explicit consent.</li>
        </ul>

        <p>
          We will not process your Personal Data in a way that is incompatible
          with the purposes for which it has been collected or authorized by you
          in accordance with this Section 4 or collect any Personal Data that is
          not required for the mentioned purposes (“
          <strong>purpose limitation principle</strong>”).
        </p>

        <p>
          For any new purpose of processing, we will ask for your separate
          explicit consent. To the extent necessary for those purposes, we take
          all reasonable steps to ensure that your Personal Data is reliable for
          its intended use, accurate, complete and current. We also undertake to
          collect only such amount and types of your Personal Data that are
          strictly required for the purposes mentioned in this Section 4 (“
          <strong>data minimization principle</strong>”).
        </p>

        <h3>
          <strong>5. YOUR RIGHTS</strong>
        </h3>

        <p>
          <strong>Access, modification, correction and erasure</strong>. You can
          send us an email at privacy@masinfionex.com to request access to,
          modification, correction, update, erasure or deletion of any Personal
          Data that you have provided to us and that we have about you. We may
          not accommodate a request to change information if we believe the
          change would violate any law or legal requirement or cause the
          information to be incorrect.
        </p>

        <p>
          <strong>EEA/UK residents</strong>. Individuals residing in the
          European Economic Area (“<strong>EEA</strong>”) and the United Kingdom
          (“
          <strong>UK</strong>”) have certain statutory rights in relation to
          their Personal Data including under the General Data Protection
          Regulation (Regulation (EU) 2016/679) (“<strong>EEA GDPR</strong>”)
          and the Data Protection, Privacy and Electronic Communications
          (Amendments etc) (EU Exit) Regulations 2020 (SI 2020/1586), as may be
          amended from time to time (“<strong>UK GDPR</strong>”) (collectively,
          the “<strong>GDPR</strong>”), including the rights specified below.
          You can exercise these rights by contacting us (for contact
          information, please, see How to Contact Us Section).
        </p>

        <ul>
          <li>
            <strong>Access to your Personal Data and data portability</strong>.
            You have a right to request information about whether we have any
            Personal Data about you, to access your Personal Data (including in
            a structured and portable form) that you have provided to us, when
            Using Lensify AI and that we have about you. You can do this by
            contacting us.
          </li>

          <li>
            <strong>
              Rectification of your Personal Data and restriction of processing
            </strong>
            . You are responsible for ensuring the accuracy of your Personal
            Data that you provide to us. Inaccurate information may affect your
            experience when Using Lensify AI features and our ability to contact
            you as described in this Privacy Policy. If you believe that your
            Personal Data is inaccurate, you have a right to contact us and ask
            us to correct such Personal Data. You also have the right to demand
            restriction of processing of your Personal Data, if you contest the
            accuracy of the Personal Data which inaccuracy is verified by us.
          </li>

          <li>
            <strong>Erasure of your Personal Data</strong>. If you believe that
            your Personal Data is no longer necessary in relation to the
            purposes for which it was collected or otherwise processed, or in
            cases where you have withdrawn your consent or object to the
            processing of your Personal Data, or where the processing of your
            Personal Data does not otherwise comply with the GDPR, you have the
            right to contact us and ask us to erase such Personal Data or by
            using a special feature in Lensify AI settings. Please be aware that
            erasing some Personal Data may affect your ability to Use Lensify
            AI.
          </li>

          <li>
            <strong>
              Right to object processing or otherwise using your Personal Data
            </strong>
            . You can object to and stop us from processing or otherwise using
            your Personal Data by contacting us. Please be aware that our
            inability to process or otherwise use some of your Personal Data may
            affect your ability to Use Lensify AI.
          </li>

          <li>
            <strong>Notification requirements</strong>. We commit to notify you
            promptly and your data protection authority within the timeframe
            specified in applicable law (72 hours) about any Personal Data
            breaches in relation to you.
          </li>

          <li>
            <strong>Notice about automated decision-making</strong>. We use
            automated decision-making tools (e.g. neural networks) that process
            your photos in order to provide you better services through Lensify
            AI.
          </li>

          <li>
            <strong>Data Protection Authorities</strong>. Subject to the GDPR,
            you have the right to lodge a complaint with your local data
            protection authority about any of our activities that you deem are
            not compliant with the GDPR.
          </li>
        </ul>

        <p>
          Please keep in mind that in case of a vague access, erasure, objection
          request or any other request in exercise of the mentioned rights we
          may engage you in a dialogue to better understand the motivation for
          the request and to locate responsive information. In case this is
          impossible, we reserve the right to refuse granting your request.
        </p>

        <p>
          Following the provisions of the GDPR, we might also ask you to prove
          your identity (for example, by requesting an ID or other proof) in
          order for you to invoke the mentioned rights. This is made to ensure
          that no right of third parties are violated by your request, and the
          mentioned rights are exercised by an actual Personal Data subject or
          an authorized person.
        </p>

        <p>
          Please note that we will grant your request within 30 days after
          receiving it, but it may take up to 90 days in some cases, for example
          for full erasure of your Personal Data stored in our backup systems –
          this is due to the size and complexity of the systems we use to store
          data.
        </p>

        <p>
          <strong>Opting Out of Promotional Emails</strong>
        </p>

        <p>
          IF REQUIRED BY LAW, WE WILL ASK FOR YOUR CONSENT TO SEND YOU
          PROMOTIONAL AND MARKETING EMAILS. IF YOU WISH TO OPT OUT OF OUR
          PROMOTIONAL AND MARKETING EMAILS, YOU CAN OPT OUT FROM OUR PROMOTIONAL
          AND MARKETING EMAIL LIST BY USING ANY OF THE FOLLOWING METHODS:
        </p>

        <ul>
          <li>
            by following the opt-out links in any marketing email sent to you;
            or
          </li>

          <li>through Lensify AI settings on your mobile device; or</li>

          <li>
            by contacting us at any time using the contact details in How to
            Contact Us Section.
          </li>
        </ul>

        <p>
          <strong>Bases for Using Your Personal Data</strong>. If you reside in
          the EEA or the UK we are only allowed to use your Personal Data when
          certain conditions apply. These conditions are called “legal bases”
          and we typically rely on one of four:
        </p>

        <ul>
          <li>
            <strong>Contract</strong>. One reason we might use your Personal
            Data is because you formed a contract with us by accepting the
            Lensify AI terms of use, and use of your Personal Data is necessary
            for the performance of the contract. For example, to enable you to
            access and use Lensify AI or send you information that you have
            requested.
          </li>

          <li>
            <strong>Legitimate interest</strong>. Another reason we might use
            your information is because we have a legitimate interest in doing
            so. For example, we need to use your Personal Data to operate,
            protect, improve and optimize Lensify AI and your User experience,
            such as to perform analytics and conduct research.
          </li>

          <li>
            <strong>Consent</strong>. In some cases we will ask for consent to
            use your Personal Data for specific purposes. If we do, we will make
            sure you can revoke your consent in Lensify AI settings or by
            contacting us.
          </li>

          <li>
            <strong>Legal obligation</strong>. We may be required to use your
            Personal Data to comply with the law, for example, when we need to
            take action to protect our Users.
          </li>
        </ul>

        <p>
          <strong>Your California Privacy Rights</strong>. If you are a
          California resident, California law may provide you with additional
          rights regarding our use of your Personal Data. To learn more about
          your California privacy rights, visit (i) the following link and (ii)
          our CCPA Privacy Notice for California Residents.
        </p>

        <h3>
          <strong>6. SHARING OF COLLECTED INFORMATION</strong>
        </h3>

        <p>
          We may disclose aggregated information about our Users and information
          that does not identify any individual without any restriction. We may
          remove parts of data that can identify you and share anonymized data
          with any person.
        </p>

        <p>
          We do not rent or sell your Personal Data, including photos, to any
          third parties outside the Company or its Affiliates (as defined
          below).
        </p>

        <p>
          <strong>Parties with whom we may share your Personal Data</strong>. We
          do not share or transfer your photos to third parties (with the
          exception of uploading the photos to our cloud providers Google Cloud
          Platform and Amazon Web Services to provide online processing function
          and storing and deleting such photos as described in this Privacy
          Policy).
        </p>

        <p>
          We use only secure places of storage, such as Google Cloud Platform
          and Amazon Web Services. Google LLC and Amazon Web Services, Inc. are
          located in the United States of America. To learn about data
          protection practices of Google LLC please visit{" "}
          <a href="https://cloud.google.com/security/gdpr">
            the following link
          </a>
          . To learn about data protection practices of Amazon Web Services,
          Inc. please visit{" "}
          <a href="https://aws.amazon.com/compliance/gdpr-center/?nc1=h_ls">
            the following link
          </a>
          . We may share your Personal Data as well as other collected
          information (including but not limited to information from cookies,
          log files, device identifiers and Usage data) with businesses that are
          legally part of the same group of companies that we are part of,
          including our subsidiaries, or that become part of that group (“
          <strong>Affiliates</strong>”). Affiliates may use this information to
          help provide, understand, and improve Lensify AI (including by
          providing analytics). Such Affiliates are bound by the rules of this
          Privacy Policy.
        </p>

        <p>
          We also may share your Personal Data and other collected information
          with third-party organizations such as contractors and service
          providers that we use to support our business and who are bound by
          confidentiality and data protection terms (consistent with this
          Privacy Policy) to keep your Personal Data confidential and use it
          only for the purposes for which we disclose it to them (and as
          directed and authorized by us) (“
          <strong>Service Providers</strong>
          ”).&nbsp;
        </p>

        <p>
          Such Service Providers include (i) cloud providers Google Cloud
          Platform and Amazon Web Services, that we use to apply photo editing
          features of Lensify AI to your photos and to store and delete such
          photos, as described in this Privacy Policy, (ii) third parties that
          may use your photos collected by us in the ways described in this
          Privacy Policy and where legally required with your explicit consent,
          (iii) third-party analytics, as specified in this Privacy Policy, (iv)
          email delivery services and (v) professional advisors, such as
          lawyers, auditors and insurers, in the course of the professional
          services that they render to us.
        </p>

        <p>
          <strong>Meta API Conversions</strong>. We also utilize Meta API
          service called{" "}
          <a href="https://www.facebook.com/business/help/2041148702652965?id=818859032317965">
            “API Conversions”
          </a>
          which creates a connection between our marketing data and Meta
          systems. Meta API Conversions, among other things, help us in
          optimizing ad targeting. According to these purposes, we send your
          hashed email addresses to Meta API Conversions service.
        </p>

        <p>
          <strong>What happens in the event of a change of control</strong>. If
          we sell or otherwise transfer part or the whole of the Company or our
          assets to another organization (e.g., in the course of a transaction
          like a merger, divestiture, restructuring, reorganization,
          acquisition, bankruptcy, dissolution, liquidation), your Personal Data
          and any other collected information may be among the items sold or
          transferred. The buyer or transferee will have to honor the
          commitments we have made in this Privacy Policy.
        </p>

        <p>
          <strong>Responding to legal requests and preventing harm</strong>. We
          may access, preserve and share your information in response to a legal
          (like a search warrant, court order or subpoena), government or
          regulatory request if we have a good faith belief that the law
          requires us to do so. This may include responding to legal, government
          or regulatory requests from jurisdictions outside of the United States
          where we have a good faith belief that the response is required by law
          in that jurisdiction, affects Users in that jurisdiction, and is
          consistent with internationally recognized standards. We may also
          access, preserve and share information when we have a good faith
          belief it is necessary to: (i) detect, prevent and address fraud and
          other illegal activity; (ii) protect ourselves, you and others,
          including as part of investigations; and (iii) prevent death or
          imminent bodily harm. Information we receive about you may be
          accessed, processed and retained for an extended period of time when
          it is the subject of a legal request or obligation, governmental
          investigation, or investigations concerning possible violations of our
          terms or policies, or otherwise to prevent harm.
        </p>

        <h3>
          <strong>7. DATA SECURITY</strong>
        </h3>

        <p>
          We use reasonable and appropriate information security safeguards to
          help keep the collected information secure and to secure it from
          accidental loss and unauthorized access, use, alteration and
          disclosure. Unfortunately, the transmission of information via the
          internet is not completely secure. Although we do our best to protect
          your collected information, we cannot guarantee the security of the
          collected information transmitted to or through Lensify AI or an
          absolute guarantee that such information may not be accessed,
          disclosed, altered, or destroyed. Any transmission of your collected
          information is at your own risk. We are not responsible for
          circumvention of security measures contained in Lensify AI. Please
          understand that there is no ideal technology or measure to maintain
          100% security. Among others, we utilize the following information
          security measures:
        </p>

        <ul>
          <li>Pseudonymization of certain categories of your Personal Data.</li>

          <li>Encryption of your Personal Data in transit and in rest.</li>

          <li>
            All collected information is stored on our secure servers behind
            firewalls.
          </li>

          <li>
            Organizational and legal measures. For example, our employees and
            employees of our subsidiaries have different levels of access to
            your Personal Data and only those in charge of data management get
            access to your Personal Data and only for limited purposes required
            for the operation of Lensify AI. We impose strict liability on such
            employees for any disclosures, unauthorized accesses, alterations,
            destructions and misuses of your Personal Data.
          </li>

          <li>
            Conducting periodical data protection impact assessments in order to
            ensure that the Company fully adheres to the principles of “
            <strong>privacy by design</strong>”, “
            <strong>privacy by default</strong>” and others. We also commit to
            undertake a privacy audit in case of the Company’s merger or
            takeover.
          </li>
        </ul>

        <p>
          The safety and security of your information also depends on you. Your
          privacy settings may also be affected by changes the social media
          services, through which you may share your photos, make to their
          services. We are not responsible for the functionality, privacy or
          security measures of any other organization.
        </p>

        <p>
          <strong>Contact us</strong>. You can provide your feedback or report a
          technical issue by using the “Send Feedback” button in Lensify AI
          settings. The email will be sent from your personal email to
          feedback@masinfionexi.com, and the message will contain the following
          diagnostics information that will help us to resolve your
          issue/address your request:
        </p>

        <ul>
          <li>Phone model.</li>

          <li>Device OS version.</li>

          <li>Platform (iOS/Android).</li>

          <li>App version.</li>

          <li>User device ID.</li>

          <li>Language.</li>

          <li>Remaining space.</li>
        </ul>

        <p>
          This information is used to assess your feedback and/or identify the
          problem if you are reporting one and is NOT USED to identify Users
          individually.
        </p>

        <p>
          <strong>Contact me</strong>. By Using Lensify AI you consent to
          receive electronic communications from us (e.g., by posting in-app
          notices in Lensify AI, push notifications or emails). These
          communications may include notices about Lensify AI (e.g.,
          changes/updates to features of Lensify AI and their scope, prices of
          in-app subscriptions, technical and security notices, updates to this
          Privacy Policy and Lensify AI terms of use), Lensify AI in-app
          subscriptions and marketing and promotional materials.&nbsp;
        </p>

        <h3>
          <strong>8. DATA RETENTION</strong>
        </h3>

        <p>
          We retain your Personal Data for as long as we need it for the
          purposes for which it was obtained or until you ask us to delete it.
          We also may retain your Personal Data for a longer period of time on
          the basis of our legitimate interests, for example, as necessary to
          comply with our tax and legal obligations, to resolve disputes, and to
          enforce our agreements. Even if we delete some or all of your Personal
          Data, we may continue to retain and use aggregated or anonymous data
          previously collected that can no longer be used for personal
          identification.
        </p>

        <h3>
          <strong>9. CROSS-BORDER TRANSFERS</strong>
        </h3>

        <p>
          The Company is based in the United States. Accordingly, your Personal
          Data may be transferred to and stored on servers in the United States.
          Your Personal Data may be transferred to any country where Google
          Cloud Platform and Amazon Web Services have/use servers.&nbsp; As the
          Company has a development and marketing team based in Cyprus, your
          Personal Data also may be transferred to and stored on servers in
          Cyprus.
        </p>

        <p>
          The data protection and privacy laws of the United States, Cyprus and
          other counties may not be as comprehensive or offer the same
          protections as the laws in your country. For example, personal data
          transferred to the United States and Cyprus may be subject to lawful
          access requests by legal authorities. You nevertheless consent to the
          transfer of your Personal Data to the United States, Cyprus and other
          countries where the Company is located, or is providing products or
          services, or from where its Affiliates and Service Providers provide
          their products and services to the Company and its Affiliates.
        </p>

        <p>
          In appropriate cases, the Company will enter into standard contractual
          clauses as approved under the European Commission Decision C(2010)593
          or decision 2004/915/EC, as applicable, to permit the transfer of your
          Personal Data to the United States, Cyprus or another country that has
          not been deemed to provide adequate level of data protection.
        </p>

        <h3>
          <strong>10. CHILDREN’S PRIVACY</strong>
        </h3>

        <p>
          <strong>General age limitation</strong>. Lensify AI is not intended
          for or directed at children under 13, and we do not knowingly collect
          or solicit any information from anyone under the age of 13 or
          knowingly allow such persons to Use Lensify AI. If you are under 13,
          do not: (i) Use or provide any information in Lensify AI or through
          any of its features, or (ii) provide any information about yourself to
          us, including your name, address, telephone number or email address.
          In the event that we learn that we have collected or received any
          Personal Data from a child under 13 without verification of parental
          consent, we will delete that information as quickly as possible. If
          you believe we might have any information from or about a child under
          13, please contact us (for contact information, please, see How to
          Contact Us Section).
        </p>

        <p>
          <strong>Age limitation for EEA residents</strong>. Due to requirements
          of the EEA GDPR you shall be at least 16 years old in order to Use
          Lensify AI. To the extent prohibited by applicable law, we do not
          allow Use of Lensify AI by the EEA residents younger than 16 years
          old. If you are aware of anyone younger than 16 Using Lensify AI,
          please contact us (for contact information, please, see How to Contact
          Us Section) and we will take required steps to delete information
          provided by such persons.
        </p>

        <h3>
          <strong>11. OTHER WEBSITES AND SERVICES</strong>
        </h3>

        <p>
          We are not responsible for the practices employed by any websites or
          services linked to or from Lensify AI, including the information or
          content contained within them. Where we have a link to a website or
          service, linked to or from Lensify AI, we encourage you to read the
          privacy policy stated on that website or service before providing
          information on or through it.
        </p>

        <h3>
          <strong>
            12. HOW TO CONTACT US. EEA/UK REPRESENTATIVE AND DATA PROTECTION
            OFFICER
          </strong>
        </h3>

        <p>
          <strong>General contact details</strong>. If you have any questions
          about this Privacy Policy or Lensify AI, please contact us via email
          at privacy@masinfionex.com or our mailing address:
        </p>

        <p>
          <strong>Lensify AI.</strong>
        </p>

        <p>
          <strong>Appointed EEA/UK representative</strong>. If you are a
          resident of the EEA or the UK and you have any questions about this
          Privacy Policy or Lensify AI, please contact us via email at
          privacy@masinfionex.com or at our EEA/UK representative mailing
          address:
        </p>

        <p>
          <strong>Data protection officer</strong>. If you are a resident of the
          EEA or the UK and you wish to exercise your rights under Section 5, or
          you have any questions about this Privacy Policy or Lensify AI, you
          can contact our data protection officer via email at
          privacy@masinfionex.com.
        </p>

        <h3>
          <strong>13. CHANGES TO OUR PRIVACY POLICY</strong>
        </h3>

        <p>
          The Company may modify or update this Privacy Policy from time to
          time, so please review it periodically. The date this Privacy Policy
          was last revised is identified at the top of the page. Your continued
          Use of Lensify AI after we make changes to this Privacy Policy is
          deemed to be acceptable by you of those changes, so please check the
          policy periodically for updates. In some cases, we may require you to
          expressly accept our new privacy policy in order to continue Using
          Lensify AI (for example, if we expand types of Personal Data collected
          from you or introduce new purposes of collection and processing).
        </p>
      </Box>
    </Box>
  );
};

export default PrivacyPolicy;
