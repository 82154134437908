import { FC, useEffect } from "react";
import styled from "@emotion/styled";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import Theme from "../../constants/theme";
import Header from "../../components/Header";
import BottomButton from "../../components/BottomButton";

import useGoogleAnalytics from "../../hooks/useGoogleAnalytics";
import { memoizedSelectApp } from "../../context/selectors/app.selector";

import { badImagesExample, goodImagesExample } from "../../constants/images";

interface IUploadYourPhotosProps {
  user: any;
}

const UploadYourPhotos: FC<IUploadYourPhotosProps> = ({ user }) => {
  const navigate = useNavigate();
  const { avatarsFor } = useSelector(memoizedSelectApp);
  const { sendPageView, sendEvent } = useGoogleAnalytics();

  const handleContinue = () => {
    const eventName = "image_upload_guide_cta";
    const properties = {
      source: "mobile",
      action: "click",
      user: user?.uid,
    };
    sendEvent(eventName, properties);
    navigate("/purchase");
  };

  useEffect(() => {
    if (avatarsFor?.name === "") {
      navigate("/home");
    } else {
      const properties = {
        action: "pageView",
        page: "image_upload_guide",
        source: "mobile",
        user: user?.uid,
      };
      sendPageView(properties);
    }
  }, [user]);

  return (
    <Container>
      <Header profile={false} title="Image Upload Guide" />
      <InfoContainer>
        <Text>Create your AI avatar in simple steps</Text>
        <HeaderText>
          Upload your photo, and watch as our advanced algorithms craft a
          personalized digital representation that captures your unique essence
          effortlessly.
        </HeaderText>
        <SubText>
          Note: Dream Avatars image generation depends completely on the quality
          of the image.
        </SubText>
        <Text>Good photo examples</Text>
        <SubText>
          Clear Photo, Portraits, Same person / photo, Adults, Plain
          backgrounds, Head tilts and angles.
        </SubText>
        <ImageContainer>
          {goodImagesExample.map((item) => (
            <Image key={item.id} src={item.image} alt="good-image" />
          ))}
        </ImageContainer>
        <Text>Bad photo examples</Text>
        <SubText>
          Close-up selfies, Open mouth, Intense facial expression, Blur pics,
          Group shots, Full-length, Kids, Covered faces, Animals, Monotonous
          pics and Nudes.
        </SubText>
        <ImageContainer>
          {badImagesExample.map((item) => (
            <Image key={item.id} src={item.image} alt="bad-image" />
          ))}
        </ImageContainer>
        <BottomButton
          text="Understood. Let's continue"
          onClick={handleContinue}
        ></BottomButton>
      </InfoContainer>
    </Container>
  );
};

export default UploadYourPhotos;

const Container = styled.div`
  width: 100%;
  height: 100vh;
  background: linear-gradient(
    to bottom right,
    ${Theme.COLORS.BG_COLOR},
    ${Theme.COLORS.BLACK_RUSSIAN}
  );
`;

const InfoContainer = styled.div`
  padding: 0 16px;
  overflow-y: scroll;
  height: calc(100vh - 142px);
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const HeaderText = styled.p`
  line-height: 1.5;
  margin-bottom: 0;
  font-size: 16px;
  color: ${Theme.COLORS.TEXT_SECONDARY_COLOR};
`;

const Text = styled.p`
  line-height: 1.5;
  margin-bottom: 0;
  font-size: 16px;
  font-weight: bold;
  color: ${Theme.COLORS.IRIS_BLUE};
`;

const SubText = styled.p`
  line-height: 1.5;
  margin-bottom: 20px;
  font-size: 15px;
  color: ${Theme.COLORS.TEXT_SECONDARY_COLOR};
`;

const ImageContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: repeat(2, 1fr);
  gap: 16px;
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  margin: 8;
  borderradius: 10;
  border-radius: 10px;
`;
