import { FC } from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  styled,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import Theme from "../constants/theme";
import Login from "../Mobile/screens/Login";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiPaper-root": {
    width: 680,
    maxWidth: 800,
    borderRadius: 16,
    background: Theme.COLORS.BLACK_RUSSIAN,
    overflow: "auto",
    "::-webkit-scrollbar": {
      display: "none",
    },
    scrollbarWidth: "none",
    color: Theme.COLORS.LIGHT_GRAY,
  },
  "& .MuiDialogTitle-root": {
    fontSize: 17,
    fontWeight: "bold",
    color: Theme.COLORS.TEXT_SECONDARY_COLOR,
    background: Theme.COLORS.BG_COLOR,
  },
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(2),
  },
}));

interface ISignInModalProps {
  open: boolean;
  modalTitle: string;
  //   disabledBuyNow: boolean;
  handleClose: () => void;
}

const SignInModal: FC<ISignInModalProps> = ({
  open,
  modalTitle,
  handleClose,
}) => {
  return (
    <BootstrapDialog
      open={open}
      aria-labelledby="signin-dialog-title"
      sx={{
        background: "rgba(0, 0, 0, 0.6)",
      }}
      onClose={handleClose}
    >
      <DialogTitle sx={{ m: 0, p: 2 }} id="signin-dialog-title">
        {modalTitle}
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: Theme.COLORS.TEXT_SECONDARY_COLOR,
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent
        dividers
        sx={{
          "::-webkit-scrollbar": {
            display: "none",
          },
          scrollbarWidth: "none",
        }}
      >
        <Login />
      </DialogContent>
    </BootstrapDialog>
  );
};

export default SignInModal;
