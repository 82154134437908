import { FC } from "react";
import styled from "@emotion/styled";
import { Button } from "@mui/material";

import Theme from "../constants/theme";

interface IErrorProps {
  errorMessge: string;
  showRetryCta?: boolean;
}

const Error: FC<IErrorProps> = ({ errorMessge, showRetryCta }) => {
  return (
    <Container>
      <ErrorText>{errorMessge}</ErrorText>
      <ErrorSubtext>Please try again.</ErrorSubtext>
      {showRetryCta && (
        <Button
          variant="contained"
          color="error"
          sx={{ mt: 2, color: Theme.COLORS.WHITE }}
        >
          Retry
        </Button>
      )}
    </Container>
  );
};

export default Error;

const Container = styled.div`
  height: 100%;
  // width: 50%;
  padding: 32px;
  margin-top: 10px;
  border-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  // background: ${Theme.COLORS.BLACK_RUSSIAN};
  // box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
`;

const ErrorText = styled.p`
  margin: 0;
  font-size: 18px;
  font-weight: bold;
  color: ${Theme.COLORS.ERROR};
`;

const ErrorSubtext = styled.p`
  margin: 8px;
  font-size: 16px;
  color: ${Theme.COLORS.ERROR};
`;
