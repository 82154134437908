import { useState } from "react";
import axios from "axios";
import useSnackbar from "./useSnackbar";

const url =
  "https://us-central1-mas-lensify.cloudfunctions.net/api/phonepe/make-payment";

const usePhonepe = () => {
  const { showSnackbar } = useSnackbar();

  const [disabledBuyNow, setDisableBuyNow] = useState(false);
  const [paymentInitiated, setPaymentInitiated] = useState(false);

  const makePayment = async (payload: any, token: string) => {
    try {
      setPaymentInitiated(true);
      const response = await axios.post(url, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      window.location.replace(response.data);
    } catch (error: any) {
      console.log("[Error] fetch generate avatars for", error.message);
      if (error.response.status === 403) {
        showSnackbar(
          `Error: Session Expired. Kindly refresh page / login.`,
          "error"
        );
      } else {
        showSnackbar(`Error: ${error.message}`, "error");
      }
    } finally {
      setDisableBuyNow(false);
      setPaymentInitiated(false);
    }
  };

  return {
    disabledBuyNow,
    paymentInitiated,
    makePayment,
    setDisableBuyNow,
    setPaymentInitiated,
  };
};

export default usePhonepe;
