import { FC } from "react";
import { Box } from "@mui/material";
import styled from "@emotion/styled";

import Theme from "../constants/theme";
import VideoBanner from "./VideoBanner";
import { LENSIFY_APP_WALKTHROUGH_DESKTOP } from "../constants/images";
import { TLoggedInUser } from "../typings";

interface ISlideshowProps {
  loggedInUser: TLoggedInUser;
}

const Slideshow: FC<ISlideshowProps> = ({ loggedInUser }) => {
  return (
    <Box
      // p={2}
      width="100%"
      height={308}
      bgcolor={Theme.COLORS.BLACK_RUSSIAN}
      borderRadius={4}
      sx={{
        boxSizing: "border-box",
        display: "flex",
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
      }}
    >
      <LSection>
        <VideoBanner
          videoUrl={LENSIFY_APP_WALKTHROUGH_DESKTOP}
          loggedInUser={loggedInUser}
        />
      </LSection>
    </Box>
  );
};

export default Slideshow;

const LSection = styled.div`
  flex: 1;
  border-radius: 16px;
  overflow: hidden;
`;
