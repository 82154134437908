import { FC } from "react";
import styled from "@emotion/styled";
import { Button } from "@mui/material";

import Theme from "../constants/theme";

interface IFooterButton {
  text: string;
  stylesSelectedCount?: number;
  disabled?: boolean;
  handleContinue: () => void;
}

const FooterButton: FC<IFooterButton> = ({
  text,
  disabled,
  stylesSelectedCount,
  handleContinue,
}) => {
  return (
    <Footer>
      <Text>{`${stylesSelectedCount} / 5 Styles Selected`}</Text>
      <Button
        disabled={disabled}
        variant="contained"
        sx={{
          p: 1.5,
          fontWeight: 800,
          background: Theme.COLORS.IRIS_BLUE,
          color: Theme.COLORS.TEXT_PRIMARY_COLOR,
        }}
        onClick={handleContinue}
      >
        {text}
      </Button>
    </Footer>
  );
};

export default FooterButton;

const Footer = styled.div`
  padding: 16px 32px;
  position: fixed;
  width: calc(100% - 220px);
  left: 220px;
  bottom: 0;
  border-radius: 16px 16px 0 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.75);
  background: ${Theme.COLORS.BLACK_RUSSIAN};
`;

const Text = styled.p`
  margin: 0;
  font-size: 16px;
  font-weight: bold;
  color: ${Theme.COLORS.TEXT_SECONDARY_COLOR};
`;
