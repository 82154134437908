import styled from "@emotion/styled";

import LoginScreen from "../../Mobile/screens/Login";

import Theme from "../../constants/theme";

const Login = () => {
  return (
    <Container>
      <LeftSection />
      <MidSection>
        <LoginScreen />
      </MidSection>
      <RightSection />
    </Container>
  );
};

export default Login;

const Container = styled.div`
  width: 100%;
  height: 100vh;
  background: ${Theme.COLORS.BG_COLOR};
  display: flex;
`;

const LeftSection = styled.div`
  flex: 1;
`;

const MidSection = styled.div`
  flex: 2;
`;

const RightSection = styled.div`
  flex: 1;
`;
