import axios from "axios";
import { useState } from "react";

const URL =
  "https://t0sh1xmk3f.execute-api.eu-central-1.amazonaws.com/default/fetch-dream-avatars-tabs";

const useFetchDreamAvatarsTabs = () => {
  const [dreamAvatarsTabs, setDreamAvatarsTabs] = useState([]);
  const [fetchDreamAvatarsTabsError, setFetchDreamAvatarsTabsError] =
    useState("");

  const fetchDreamAvatarsTabs = async () => {
    setFetchDreamAvatarsTabsError("");
    setDreamAvatarsTabs([]);
    try {
      const response = await axios.post(URL);
      setDreamAvatarsTabs(response.data);
    } catch (error) {
      console.log("[Error] fetch dream avatars tabs", error);
      setFetchDreamAvatarsTabsError("Something Went Wrong!");
    }
  };

  return {
    dreamAvatarsTabs,
    fetchDreamAvatarsTabsError,
    fetchDreamAvatarsTabs,
  };
};

export default useFetchDreamAvatarsTabs;
