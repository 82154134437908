import { FC } from "react";
import styled from "@emotion/styled";

import { isMobileDevice } from "../utils/browser.util";

import Theme from "../constants/theme";
import { PRICE_BANNER } from "../constants/images";

const isMobile = isMobileDevice();

interface IPriceBannerProps {
  price: string | number;
}

const PriceBanner: FC<IPriceBannerProps> = ({ price }) => {
  return (
    <PriceBannerContainer>
      <PriceBannerImg src={PRICE_BANNER} alt="price-banner" />
      <PriceText>{`${price} `}&#8377;</PriceText>
    </PriceBannerContainer>
  );
};

export default PriceBanner;

const PriceBannerContainer = styled.div`
  width: 60px;
  height: 66px;
  position: absolute;
  left: 4px;
  top: ${isMobile ? "-9px" : "-13px"};
`;

const PriceBannerImg = styled.img`
  width: 100%;
  height: 100%;
`;

const PriceText = styled.p`
  position: relative;
  font-size: 12px;
  position: absolute;
  bottom: 0px;
  left: 15px;
  font-weight: bold;
  color: ${Theme.COLORS.TEXT_SECONDARY_COLOR};
`;
