import { FC } from "react";
import { Box } from "@mui/material";
import styled from "@emotion/styled";
import { useSelector } from "react-redux";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import { useNavigate } from "react-router-dom";
import HomeIcon from "@mui/icons-material/Home";
import LogoutIcon from "@mui/icons-material/Logout";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";

import useGoogleSignIn from "../hooks/useGoogleSignIn";
import { memoizedSelectApp } from "../context/selectors/app.selector";

import Theme from "../constants/theme";
import { isMobileDevice } from "../utils/browser.util";
import { DUMMY_PROFILE_IMAGE_URL } from "../constants/images";

interface IHeaderProps {
  title: string;
  profile: boolean;
}

const isMobile = isMobileDevice();

const Header: FC<IHeaderProps> = ({ profile, title }) => {
  const navigate = useNavigate();

  const { loggedInUser } = useSelector(memoizedSelectApp);

  const handleMyOrders = () => {
    navigate("/my-orders");
  };

  const handleHome = () => {
    navigate("/home");
  };

  const { handleLogout } = useGoogleSignIn();

  return (
    <AppBar sx={{ position: "relative", bgcolor: Theme.COLORS.BG_COLOR }}>
      <Toolbar>
        <ModalTitle>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <HeaderRightSection>
              {profile && (
                <>
                  <ProfileImage
                    src={loggedInUser?.photo || DUMMY_PROFILE_IMAGE_URL}
                  />
                  <div>
                    <HeaderText>Hello</HeaderText>
                    <HeaderSubtext>
                      {loggedInUser?.name || "Lensify User"}
                    </HeaderSubtext>
                  </div>
                </>
              )}
              {isMobile && (
                <HomeIcon
                  sx={{
                    mr: 2,
                    width: 24,
                    height: 24,
                    color: Theme.COLORS.IRIS_BLUE,
                    cursor: "pointer",
                  }}
                  onClick={handleHome}
                />
              )}
              {title}
            </HeaderRightSection>
            {loggedInUser?.idToken ? (
              <HeaderRightSection>
                <ShoppingCartIcon
                  sx={{
                    width: 24,
                    height: 24,
                    color: Theme.COLORS.IRIS_BLUE,
                    cursor: "pointer",
                  }}
                  onClick={handleMyOrders}
                />

                <LogoutIcon
                  sx={{
                    ml: 2,
                    width: 24,
                    height: 24,
                    color: Theme.COLORS.IRIS_BLUE,
                    cursor: "pointer",
                  }}
                  onClick={handleLogout}
                />
              </HeaderRightSection>
            ) : null}
          </Box>
        </ModalTitle>
      </Toolbar>
    </AppBar>
  );
};

export default Header;

const ModalTitle = styled.p`
  flex: 1;
  font-size: 17px;
  font-weight: bold;
  color: ${Theme.COLORS.IRIS_BLUE};
`;

const ProfileImage = styled.img`
  width: 32px;
  height: 32px;
  margin-right: 8px;
  border-radius: 4px;
  border: 1.5px solid ${Theme.COLORS.TEXT_SECONDARY_COLOR};
`;

const HeaderText = styled.p`
  margin: 0;
  font-size: 14px;
  font-weight: bold;
  color: ${Theme.COLORS.TEXT_SECONDARY_COLOR};
`;

const HeaderSubtext = styled.p`
  margin: 2px 0;
  font-size: 12px;
  font-weight: bold;
  color: ${Theme.COLORS.TEXT_SECONDARY_COLOR};
`;

const HeaderRightSection = styled.div`
  display: flex;
  align-items: center;
`;
