import { FC } from "react";
import {
  Box,
  CircularProgress,
  Divider,
  ImageList,
  ImageListItem,
  ImageListItemBar,
} from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import ReactPlayer from "react-player/lazy";
import { styled } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import eStyled from "@emotion/styled";

import Theme from "../constants/theme";
import { calculateTotalPrice } from "../utils/price.utils";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiPaper-root": {
    width: 680,
    maxWidth: 800,
    borderRadius: 16,
    background: Theme.COLORS.BLACK_RUSSIAN,
    overflow: "auto",
    "::-webkit-scrollbar": {
      display: "none",
    },
    scrollbarWidth: "none",
    color: Theme.COLORS.LIGHT_GRAY,
  },
  "& .MuiDialogTitle-root": {
    fontSize: 17,
    fontWeight: "bold",
    color: Theme.COLORS.TEXT_SECONDARY_COLOR,
    background: Theme.COLORS.BG_COLOR,
  },
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(2),
  },
}));

interface ICartModalProps {
  open: boolean;
  modalTitle: string;
  avatarsFor: any;
  selectedStyles: any;
  disabledBuyNow: boolean;
  paymentInitiated: boolean;
  handleClose: () => void;
  handlePurchase: () => void;
}

const CartModal: FC<ICartModalProps> = ({
  open,
  modalTitle,
  avatarsFor,
  selectedStyles,
  disabledBuyNow,
  paymentInitiated,
  handleClose,
  handlePurchase,
}) => {
  return (
    <BootstrapDialog
      open={open}
      aria-labelledby="cart-dialog-title"
      sx={{
        background: "rgba(0, 0, 0, 0.6)",
      }}
      onClose={handleClose}
    >
      <DialogTitle sx={{ m: 0, p: 2 }} id="cart-dialog-title">
        {modalTitle}
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: Theme.COLORS.TEXT_SECONDARY_COLOR,
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent
        dividers
        sx={{
          "::-webkit-scrollbar": {
            display: "none",
          },
          scrollbarWidth: "none",
        }}
      >
        <Box
          sx={{
            height: "60vh",
          }}
        >
          <Text>Avatars For</Text>
          <ImageListItem>
            <Image
              src={avatarsFor?.image}
              alt={avatarsFor?.name}
              loading="lazy"
            />
            <ImageListItemBar
              position="bottom"
              title={avatarsFor?.name}
              sx={{
                width: 114,
                borderRadius: "0 0 16px 16px",
                bottom: 4,
                color: Theme.COLORS.TEXT_SECONDARY_COLOR,
              }}
            />
          </ImageListItem>
          <Divider color={Theme.COLORS.ONYX} sx={{ height: "1px", my: 2 }} />
          <Text>Selected Styles</Text>
          <Box>
            <ImageList variant="masonry" cols={5} gap={8}>
              {selectedStyles.map((item: any) => (
                <ImageListItem key={item?.image}>
                  <Image src={item?.image} alt={item?.name} loading="lazy" />
                  <ImageListItemBar
                    position="bottom"
                    title={item?.name}
                    sx={{
                      width: 114,
                      borderRadius: "0 0 16px 16px",
                      bottom: 4,
                      color: Theme.COLORS.TEXT_SECONDARY_COLOR,
                    }}
                  />
                </ImageListItem>
              ))}
            </ImageList>
          </Box>
          <Divider color={Theme.COLORS.ONYX} sx={{ height: "1px", my: 2 }} />
          Order Details:
          <br />
          <br />
          Each style 1 image. Total 5 images.
        </Box>
      </DialogContent>
      <DialogActions sx={{ display: "flex" }}>
        Total:
        <Box
          border={`1.5px solid ${Theme.COLORS.ONYX}`}
          sx={{
            p: 1,
            flex: 1,
            mx: 2,
            borderRadius: 1,
            boxSizing: "border-box",
          }}
        >
          <Box>
            <PriceText>
              &#x20B9;{calculateTotalPrice(selectedStyles) || 295}/-
            </PriceText>
            <Price>&#x20B9;599/-</Price>
          </Box>
          {/* <Price>&#x20B9;599/-</Price> */}
        </Box>
        <Button
          autoFocus
          fullWidth
          disabled={selectedStyles?.length < 5 || disabledBuyNow}
          variant="contained"
          sx={{
            flex: 2,
            height: 61,
            fontWeight: 800,
            fontSize: 16,
            background: Theme.COLORS.IRIS_BLUE,
            color: Theme.COLORS.TEXT_PRIMARY_COLOR,
          }}
          onClick={handlePurchase}
        >
          {paymentInitiated ? <CircularProgress size={28} /> : `Place Order`}
        </Button>
      </DialogActions>
    </BootstrapDialog>
  );
};

export default CartModal;

const Text = eStyled.p`
  margin: 0;
  font-size: 16px;
  font-weight: bold;
  color: ${Theme.COLORS.IRIS_BLUE};
  margin-bottom: 12px;
`;

const Image = eStyled.img`
  width: 110px;
  height: 128px;
  border-radius: 16px;
  border: 2px solid ${Theme.COLORS.ONYX}
`;

const PriceText = eStyled.p`
  font-size: 18px;
  font-weight: bold;
  color: ${Theme.COLORS.IRIS_BLUE};
  margin: 0;
`;

const Price = eStyled.p`
  font-size: 15px;
  color: ${Theme.COLORS.SONIC_SILVER};
  margin: 0;
  margin-top: 4px;
  text-decoration: line-through;
`;
