import { FC, useEffect } from "react";
import styled from "@emotion/styled";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import Header from "../../components/Header";
import BottomButton from "../../components/BottomButton";

import useGoogleAnalytics from "../../hooks/useGoogleAnalytics";
import { memoizedSelectApp } from "../../context/selectors/app.selector";

import Theme from "../../constants/theme";
import { badImagesArray, goodImagesArray } from "../../constants/images";

type TextProps = {
  bold?: boolean;
  color?: string;
};

interface IWhatToExpectProps {
  user: any;
}

const WhatToExpect: FC<IWhatToExpectProps> = ({ user }) => {
  const navigate = useNavigate();
  const { avatarsFor } = useSelector(memoizedSelectApp);
  const { sendPageView, sendEvent } = useGoogleAnalytics();

  const handleContinue = () => {
    const eventName = "what_to_expect_continue_cta";
    const properties = {
      source: "mobile",
      action: "click",
      user: user?.uid,
    };
    sendEvent(eventName, properties);
    navigate("/upload-your-photos");
  };

  useEffect(() => {
    if (avatarsFor?.name === "") {
      navigate("/home");
    } else {
      const properties = {
        action: "pageView",
        page: "what_to_expect",
        source: "mobile",
        user: user?.uid,
      };
      sendPageView(properties);
    }
  }, [user]);

  return (
    <Container>
      <Header profile={false} title="What to expect" />
      <InfoContainer>
        <Text>
          The type of AI we utilise for Dream Avatars may generate artefacts,
          inaccuracies and defects in the output images (rarely).
          <br />
          <br />
          It is out of our control. So kindly acknowledge and accept that risk
          before continue.
        </Text>
        <Text>
          The more variations you get, the better chances for the great result!
        </Text>
        <Text bold color={Theme.COLORS.IRIS_BLUE}>
          Good Examples
        </Text>
        <ImageContainer>
          {goodImagesArray.map((item) => (
            <Image key={item.id} src={item.image} alt="bad-image" />
          ))}
        </ImageContainer>
        <Text bold color={Theme.COLORS.IRIS_BLUE}>
          Bad Examples
        </Text>
        <ImageContainer>
          {badImagesArray.map((item) => (
            <Image key={item.id} src={item.image} alt="bad-image" />
          ))}
        </ImageContainer>
        <BottomButton text="Accept & Continue" onClick={handleContinue} />
      </InfoContainer>
    </Container>
  );
};

export default WhatToExpect;

const Container = styled.div`
  width: 100%;
  height: 100vh;
  background: linear-gradient(
    to bottom right,
    ${Theme.COLORS.BG_COLOR},
    ${Theme.COLORS.BLACK_RUSSIAN}
  );
`;

const InfoContainer = styled.div`
  padding: 0 16px;
  overflow-y: scroll;
  height: calc(100vh - 142px);
`;

const Text = styled.p<TextProps>`
  line-height: 1.5;
  margin-bottom: 20px;
  font-size: 16px;
  font-weight: ${(props) => (props.bold ? "bold" : 500)};
  color: ${(props) => props.color || Theme.COLORS.TEXT_SECONDARY_COLOR};
`;

const ImageContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: repeat(2, 1fr);
  gap: 16px;
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  margin: 8;
  borderradius: 10;
  border-radius: 10px;
`;
