import styled from "@emotion/styled";

import Theme from "./theme";

type TitleTextProps = {
  color?: string;
};

type SubTextTextProps = {
  color?: string;
  marginTop?: string;
};

export const Text = styled.p`
  color: ${Theme.COLORS.WHITE};
  font-size: 34px;
  text-align: center;
  font-weight: bold;
  margin-bottom: 0;
`;

export const SubText = styled.p<SubTextTextProps>`
  color: ${(props) => props.color || Theme.COLORS.WHITE};
  font-size: 2vh;
  text-align: center;
  font-weight: 500;
  opacity: 0.9;
  margin: 0;
  margin-top: ${(props) => props.marginTop || 0};
`;

export const TitleText = styled.p<TitleTextProps>`
  color: ${(props) => props.color || Theme.COLORS.WHITE};
  font-size: 22px;
  text-align: center;
  font-weight: bold;
  margin-bottom: 0;
`;
