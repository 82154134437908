import { useDispatch } from "react-redux";

import { setUser } from "../context/slices/app.slice";

const useSetUser = () => {
  const dispatch = useDispatch();

  const setLoggedInUser = (user: any) => {
    if (user) {
      const { accessToken, displayName, email, uid, photoURL } = user;
      const payload = {
        accessToken,
        displayName,
        email,
        uid,
        photoURL,
      };
      dispatch(setUser(payload));
    }
  };

  return { setLoggedInUser };
};

export default useSetUser;
