import { FC, forwardRef } from "react";
import Slide from "@mui/material/Slide";
import { Backdrop } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import ReactPlayer from "react-player/lazy";
import { useNavigate } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { TransitionProps } from "@mui/material/transitions";

import styled from "@emotion/styled";
import { makeStyles } from "@mui/styles";

import Theme from "../constants/theme";
import { SubText } from "../constants/styles";
import useGoogleAnalytics from "../hooks/useGoogleAnalytics";
import { TLoggedInUser } from "../typings";

const useStyles = makeStyles({
  dialogPaper: {
    background: `linear-gradient(to bottom right, ${Theme.COLORS.BG_COLOR}, ${Theme.COLORS.BLACK_RUSSIAN})`,
    boxShadow: "none",
    borderRadius: "8px 8px 0 0",
  },
});

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface IDropdownModalProps {
  modalTitle: string;
  open: boolean;
  loggedInUser: TLoggedInUser;
  setOpen: (value: boolean) => void;
}

const DropdownModal: FC<IDropdownModalProps> = ({
  open,
  modalTitle,
  loggedInUser,
  setOpen,
}) => {
  const navigate = useNavigate();
  const classes = useStyles();
  const { sendEvent } = useGoogleAnalytics();

  const handleClose = () => {
    setOpen(false);
  };

  const handleTryNow = () => {
    handleClose();
    const eventName = "try_now_cta";
    const properties = {
      source: "mobile",
      action: "click",
      user: loggedInUser?.uid,
    };
    sendEvent(eventName, properties);
    navigate("/generate-avatars-for");
  };

  return (
    <Backdrop
      sx={{ color: Theme.COLORS.TEXT_SECONDARY_COLOR, zIndex: 1 }}
      open={open}
      onClick={handleClose}
    >
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
        sx={{ top: 70, maxWidth: 768, left: 0, right: 0, margin: "auto" }}
        PaperProps={{
          className: classes.dialogPaper,
        }}
      >
        <AppBar sx={{ position: "relative", bgcolor: Theme.COLORS.BG_COLOR }}>
          <Toolbar>
            <ModalTitle>{modalTitle}</ModalTitle>
            <IconButton
              edge="end"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <VideoContainer>
          <ReactPlayer
            width="100%"
            height="100%"
            url={
              "https://lensify.masinfionex.com/assets/video/dream-avatar.mp4"
            }
            playing={open}
            loop
          />
        </VideoContainer>
        <SubTextContainer>
          <SubText>
            Create stunning AI-powered avatars from your photos with our
            cutting-edge technology.
          </SubText>
        </SubTextContainer>
        <Button
          fullWidth
          variant="contained"
          sx={{
            fontSize: 16,
            marginTop: 3,
            left: "32px",
            bottom: "32px",
            padding: "14px",
            borderRadius: 2,
            fontWeight: 800,
            position: "absolute",
            width: "calc(100% - 64px)",
            bgcolor: Theme.COLORS.IRIS_BLUE,
            color: Theme.COLORS.TEXT_PRIMARY_COLOR,
          }}
          onClick={handleTryNow}
        >
          Try Now
        </Button>
      </Dialog>
    </Backdrop>
  );
};

export default DropdownModal;

const ModalTitle = styled.p`
  flex: 1;
  font-size: 16px;
  font-weight: bold;
  color: ${Theme.COLORS.TEXT_SECONDARY_COLOR};
`;

const VideoContainer = styled.div`
  border-radius: 10px;
  width: calc(100% - 64px);
  height: 55%;
  background: ${Theme.COLORS.TEXT_SECONDARY_COLOR};
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;
  margin-top: 32px;
`;

const SubTextContainer = styled.div`
  padding: 32px;
`;
