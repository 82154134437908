import axios from "axios";
import { useState } from "react";

const URL =
  "https://8itcb8wjs1.execute-api.eu-central-1.amazonaws.com/default/generate-avatars-for";

const useFetchGenerateAvatarsFor = () => {
  const [fetchingGenerateAvatarsFor, setFetchingGenerateAvatarsFor] =
    useState(true);
  const [generateAvatarsForList, setGenerateAvatarsForList] = useState([]);
  const [generateAvatarsForError, setGenerateAvatarsForError] = useState("");

  const fetchGenerateAvatarsFor = async () => {
    setFetchingGenerateAvatarsFor(true);
    setGenerateAvatarsForError("");
    setGenerateAvatarsForList([]);
    try {
      const response = await axios.post(URL);
      setGenerateAvatarsForList(response.data);
    } catch (error) {
      console.log("[Error] fetch generate avatars for", error);
      setGenerateAvatarsForError("Something Went Wrong!");
    } finally {
      setFetchingGenerateAvatarsFor(false);
    }
  };

  return {
    fetchingGenerateAvatarsFor,
    generateAvatarsForList,
    generateAvatarsForError,
    fetchGenerateAvatarsFor,
  };
};

export default useFetchGenerateAvatarsFor;
