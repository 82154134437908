import { FC } from "react";
import {
  Alert,
  Slide,
  Snackbar as SnackbarComponent,
  SnackbarOrigin,
} from "@mui/material";

interface State extends SnackbarOrigin {
  open: boolean;
}

interface ISnackbarProps {
  open: boolean;
  message: string;
  severity: "error" | "info" | "success" | "warning";
  vertical: "top" | "bottom";
  horizontal: "left" | "center" | "right";
  handleClose: () => void;
}

const Snackbar: FC<ISnackbarProps> = ({
  open,
  message,
  severity,
  vertical,
  horizontal,
  handleClose,
}) => {
  return (
    <SnackbarComponent
      open={open}
      autoHideDuration={5000}
      key={vertical + horizontal}
      TransitionComponent={Slide}
      anchorOrigin={{ vertical, horizontal }}
      onClose={handleClose}
    >
      <Alert
        onClose={handleClose}
        severity={severity}
        variant="filled"
        sx={{ width: "100%" }}
      >
        {message}
      </Alert>
    </SnackbarComponent>
  );
};

export default Snackbar;
