import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "@mui/material";
import styled from "@emotion/styled";

import OnboardingPageAnimation from "../../components/OnboardingPageAnimation";

import * as kidsAnimationData from "../../assets/animation/kidsAnimation.json";
import * as onboardingAnimationData from "../../assets/animation/onboardingAnimation.json";
import { isMobileDevice } from "../../utils/browser.util";

import Theme from "../../constants/theme";
import useGoogleAnalytics from "../../hooks/useGoogleAnalytics";

type ContainerProps = {
  isMobile: boolean;
};

const Onboarding = () => {
  const navigate = useNavigate();
  const { sendPageView, sendEvent } = useGoogleAnalytics();

  const isMobile = isMobileDevice();

  const getStartedHandler = () => {
    const eventName = "onboarding_get_started_cta";
    const properties = {
      source: isMobile ? "mobile" : "desktop",
      action: "click",
    };
    sendEvent(eventName, properties);
    navigate("/home");
  };

  useEffect(() => {
    const properties = {
      action: "pageView",
      page: "onboarding",
      source: isMobile ? "mobile" : "desktop",
    };
    sendPageView(properties);
  }, [isMobile]);

  return (
    <Container isMobile={isMobile}>
      <OnboardingPageAnimation
        animationData={isMobile ? onboardingAnimationData : kidsAnimationData}
        height={isMobile ? "100vh" : "100%"}
      />
      <Banner>
        <TextContainer>
          <Text>Dream Avatars</Text>
          <SubText>
            Create personalized, impressive avatars with the help of powerful AI
            technology.
          </SubText>
        </TextContainer>
        <Button
          fullWidth
          variant="contained"
          sx={{
            fontSize: 16,
            marginTop: 1,
            padding: "14px",
            borderRadius: 2,
            fontWeight: 800,
            maxWidth: 600,
            color: Theme.COLORS.TEXT_PRIMARY_COLOR,
            backgroundColor: Theme.COLORS.IRIS_BLUE,
          }}
          onClick={getStartedHandler}
        >
          Get Started
        </Button>
      </Banner>
    </Container>
  );
};

export default Onboarding;

const Container = styled.div<ContainerProps>`
  width: 100%;
  height: ${(props) => (props.isMobile ? "100vh" : "calc(100vh - 220px)")};
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${Theme.COLORS.BG_COLOR};
`;
const Banner = styled.div`
  padding: 16px;
  bottom: 0px;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 1;
  width: 100%;
  box-sizing: border-box;
  border-radius: 16px 16px 0 0;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const TextContainer = styled.div``;

const Text = styled.p`
  color: ${Theme.COLORS.TEXT_SECONDARY_COLOR};
  font-size: 30px;
  text-align: center;
  font-weight: bold;
  margin: 0;
`;

const SubText = styled.p`
  color: ${Theme.COLORS.TEXT_SECONDARY_COLOR};
  font-size: 17px;
  text-align: center;
  font-weight: 500;
  opacity: 0.9;
`;
