const isEmpty = (string: any) => {
  if (string.trim() === "") return true;
  else return false;
};

export const validateLoginData = (data: any) => {
  let errors: any = {};
  if (isEmpty(data.email)) errors.message = "Email must not be empty";
  if (isEmpty(data.password)) errors.message = "Password must not be empty";
  return {
    errors,
    valid: Object.keys(errors).length === 0 ? true : false,
  };
};

const isEmail = (email: string) => {
  const emailRegEx =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (email.match(emailRegEx)) return true;
  else return false;
};

export const validateSignUpData = (data: any) => {
  let errors: any = {};
  if (isEmpty(data.email)) {
    errors.message = "Email must not be empty";
  } else if (!isEmail(data.email)) {
    errors.message = "Email must be a valid email address";
  }
  // if (isEmpty(data.name)) errors.message = "Name must not be empty";
  // if (isEmpty(data.password)) errors.message = "Password must not be empty";
  // if (data.password !== data.confirmPassword)
  //   errors.message = "Passowrds must be the same";

  return {
    errors,
    valid: Object.keys(errors).length === 0 ? true : false,
  };
};
