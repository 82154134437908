import { FC } from 'react';
// @ts-ignore
import Lottie from 'react-lottie';


interface IOnboardingPageAnimationProps {
  animationData: any
  height?: string | number
}

const OnboardingPageAnimation: FC<IOnboardingPageAnimationProps> = ({ animationData, height }) => {

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  return <div><Lottie options={defaultOptions}
    height={height || "100vh"}
    speed={0.3}
    isStopped={false}
    isPaused={false} /></div>
};

export default OnboardingPageAnimation;