export default {
  COLORS: {
    PRIMARY: "#4A90E2",
    SECONDARY: "#FF6F61",
    GREEN: "#42B883",
    LIGHT_GRAY: "#F5F5F5",
    DARK_GRAY: "#333333",
    MEDIUM_GRAY: "#666666",
    INFO: "#00BCD4",
    ERROR: "#F44336",
    SUCCESS: "#4CAF50",
    WARNING: "#FF9800",
    WHITE: "#FFFFFF",
    BLACK: "#000000",
    RAISIN_BLACK: "#222224",
    CHINESE_BLACK: "#141414",
    YELLOW: "#FFFF00",
    GOLDEN_YELLOW: "#FFDF00",
    SONIC_SILVER: "#7B7B79",
    VERY_DARK_GRAY: "#1C1C1C",
    VIOLET: "#8A2BE2",
    PINK: "#FF69B4",
    SOFT_PINK: "#FFB6C1",
    DARK_MAGENTA: "#9C27B0",
    SMOKE_WHITE: "#F5F5F5",
    LIGHT_PINK: "#FFE6EE",
    BG_COLOR: "#16181E",
    BLACK_RUSSIAN: "#21242D",
    IRIS_BLUE: "#00B9AE",
    TEXT_PRIMARY_COLOR: "#16181E",
    TEXT_SECONDARY_COLOR: "#F9F9F9",
    ONYX: "#373942",
  },
};
