import { FC, useEffect, useState } from "react";
import styled from "@emotion/styled";
import { Box, Divider } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import WhatToExpect from "./WhatToExpect";
import Error from "../../components/Error";
import Banner from "../../components/Banner";
import Snackbar from "../../components/Snackbar";
import UploadYourPhotos from "./UploadYourPhotos";
import Slideshow from "../../components/Slideshow";
import CartModal from "../../components/CartModal";
import SignInModal from "../../components/SignInModal";
import FooterButton from "../../components/FooterButton";
import AvatarsForCard from "../../components/AvatarsForCard";
import HorizontalTabs from "../../components/HorizontalTabs";
// import DreamAvatarsModal from "../../components/DreamAvatarsModal";
import GenerateAvatarsForShimmer from "../../components/shimmer/GenerateAvatarsForShimmer";

import {
  setAvatarsFor,
  setOpenSignInModal,
  setSelectedStyles,
  setShowSnackbar,
} from "../../context/slices/app.slice";
import { memoizedSelectApp } from "../../context/selectors/app.selector";

import useSetUser from "../../hooks/useSetUser";
import useGoogleFirestore from "../../hooks/useGoogleFirestore";
import useFetchSelectStyle from "../../hooks/useFetchSelectStyle";
import useFetchDreamAvatarsTabs from "../../hooks/useFetchDreamAvatarsTabs";
import useFetchGenerateAvatarsFor from "../../hooks/useFetchGenerateAvatarsFor";

import { TitleText } from "../../constants/styles";
import useSnackbar from "../../hooks/useSnackbar";
import usePhonepe from "../../hooks/usePhonepe";
import useGoogleSignIn from "../../hooks/useGoogleSignIn";
import useGoogleAnalytics from "../../hooks/useGoogleAnalytics";

import { calculateTotalPrice } from "../../utils/price.utils";

import Theme from "../../constants/theme";

interface IHomeProps {
  user: any;
}

const Home: FC<IHomeProps> = ({ user }) => {
  const {
    avatarsFor,
    selectedStyles,
    showSnackbar,
    snackbarMessage,
    snackbarSeverity,
    loggedInUser,
    openSignInModal,
  } = useSelector(memoizedSelectApp);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { setLoggedInUser } = useSetUser();
  const { checkUserExists, addNewUser } = useGoogleFirestore();

  const {
    fetchingGenerateAvatarsFor,
    generateAvatarsForList,
    generateAvatarsForError,
    fetchGenerateAvatarsFor,
  } = useFetchGenerateAvatarsFor();

  const {
    dreamAvatarsTabs,
    fetchDreamAvatarsTabsError,
    fetchDreamAvatarsTabs,
  } = useFetchDreamAvatarsTabs();

  const {
    fetchingDreamAvatarStyles,
    dreamAvatarsStylesList,
    fetchDreamAvatarsStylesError,
    fetchDreamAvatarsStyles,
    filterDreamAvatarsStyles,
    updateDreamAvatarsStyles,
  } = useFetchSelectStyle();

  const { closeSnackbar } = useSnackbar();

  const { disabledBuyNow, paymentInitiated, makePayment, setDisableBuyNow } =
    usePhonepe();

  const { sendPageView, sendEvent } = useGoogleAnalytics();

  const { isSignInViaEmailLink } = useGoogleSignIn();

  // const [openSignInModal, setOpenSignInModal] = useState(false);
  const [openCartModal, setOpenCartModal] = useState(false);
  const [selectedAvatarsForIndex, setSelectedAvatarsForIndex] = useState(-1);

  const [tabValue, setTabValue] = useState(0);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
    filterDreamAvatarsStyles(dreamAvatarsTabs[newValue]);
  };

  // const handleModalClose = () => {
  //   setOpenModal(false);
  // };

  // const handleTryNow = () => {
  //   setOpenModal(false);
  //   navigate("/generate-avatars-for");
  // };

  // const handleDreamAvatarsOnClick = () => {
  //   setOpenModal(true);
  // };

  const scrollToDiv = (id: string) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  const handleSelectAvatarsFor = (item: any, index: number) => {
    setSelectedAvatarsForIndex(index);
    const value = item?.name.toLowerCase();
    dispatch(setAvatarsFor(item));
    fetchDreamAvatarsTabs();
    fetchDreamAvatarsStyles(value);
    const eventName = "select_avatars_for_cta";
    const properties = {
      source: "desktop",
      action: "click",
      user: loggedInUser?.uid,
      value,
    };
    sendEvent(eventName, properties);
    scrollToDiv("select_styles");
  };

  const handleSelectStyleCheckbox = (item: any, index: number) => {
    const eventName = "select_style_checkbox";
    const properties = {
      source: "desktop",
      action: "click",
      user: loggedInUser?.uid,
      value: item?.name,
    };
    sendEvent(eventName, properties);
    const isAlreadySelected = selectedStyles.some(
      (style: any) => style?.image === item?.image
    );
    if (!item?.isSelected) {
      if (!isAlreadySelected && selectedStyles?.length < 5) {
        const updatedStyles = [...selectedStyles, item];
        updateDreamAvatarsStyles(index);
        dispatch(setSelectedStyles(updatedStyles));
      } else {
        const payload = {
          showSnackbar: true,
          snackbarMessage: "Max 5 styles allowed at a time",
          snackbarSeverity: "info",
        };
        dispatch(setShowSnackbar(payload));
      }
    } else {
      if (isAlreadySelected) {
        const updatedStyles = selectedStyles.filter(
          (style: any) => style?.image !== item?.image
        );
        updateDreamAvatarsStyles(index);
        dispatch(setSelectedStyles(updatedStyles));
      }
    }
  };

  const handleCartModal = () => {
    const eventName = "review_order_cta";
    const properties = {
      source: "desktop",
      action: "click",
      user: loggedInUser?.uid,
      value: !openCartModal ? "open" : "close",
    };
    sendEvent(eventName, properties);
    setOpenCartModal(!openCartModal);
  };

  const handleSignInModal = () => {
    const eventName = "sign_in_modal";
    const properties = {
      source: "desktop",
      action: "click",
      user: loggedInUser?.uid,
      value: !openSignInModal ? "open" : "close",
    };
    sendEvent(eventName, properties);
    setOpenCartModal(false);
    dispatch(setOpenSignInModal(!openSignInModal));
  };

  const handlePurchase = () => {
    if (!loggedInUser?.idToken) {
      dispatch(setOpenSignInModal(true));
      return;
    }
    const eventName = "place_order_cta";
    const properties = {
      source: "desktop",
      action: "click",
      user: loggedInUser?.uid,
    };
    sendEvent(eventName, properties);
    // setOpenCartModal(!openCartModal);
    setDisableBuyNow(true);
    const payload = {
      price: calculateTotalPrice(selectedStyles) || 295,
      uid: loggedInUser?.uid,
      email: loggedInUser?.email,
      name: loggedInUser?.name,
      avatarsFor: avatarsFor?.name.toLowerCase(),
      selectedStyles,
    };
    makePayment(payload, loggedInUser?.idToken);
  };

  const doesUserExists = async () => {
    const payload = {
      email: user.email,
    };
    const userExits = await checkUserExists(payload, user.accessToken);
    if (!userExits) {
      const data = {
        newUserData: {
          name: user.displayName,
          email: user.email,
          photoURL: user.photoURL,
          uid: user.uid,
          registerDate: new Date().toISOString(),
        },
      };
      addNewUser(data, user.accessToken);
    }
    setLoggedInUser(user);
  };

  useEffect(() => {
    fetchGenerateAvatarsFor();
    const properties = {
      action: "pageView",
      page: "home",
      source: "desktop",
      user: user?.uid,
    };
    sendPageView(properties);
  }, []);

  useEffect(() => {
    if (user) {
      doesUserExists();
    } else {
      isSignInViaEmailLink();
    }
  }, [user]);

  return (
    <Container>
      <LeftSection>
        <Slideshow loggedInUser={loggedInUser} />
        {fetchingGenerateAvatarsFor ? (
          <GenerateAvatarsForShimmer length={4} />
        ) : (
          <Box mt={6}>
            {generateAvatarsForError === "" ? (
              <Box>
                <TitleText color={Theme.COLORS.IRIS_BLUE}>
                  Generate Avatars For
                </TitleText>
                <AvatarsForContainer>
                  {generateAvatarsForList.map((item: any, index: number) => (
                    <AvatarsForCard
                      key={item?.name}
                      item={item}
                      selected={selectedAvatarsForIndex === index}
                      showPriceBanner={false}
                      handleClick={() => handleSelectAvatarsFor(item, index)}
                    />
                  ))}
                </AvatarsForContainer>
              </Box>
            ) : (
              <Error errorMessge={generateAvatarsForError} />
            )}
          </Box>
        )}
        {fetchingDreamAvatarStyles ? (
          <GenerateAvatarsForShimmer horizontalTabs length={4} />
        ) : (
          <Box mt={6}>
            <Box>
              {avatarsFor?.name !== "" && (
                <>
                  <TitleText color={Theme.COLORS.IRIS_BLUE}>
                    Select Styles
                  </TitleText>
                  {fetchDreamAvatarsTabsError === "" ? (
                    <HorizontalTabs
                      dreamAvatarsTabs={dreamAvatarsTabs}
                      value={tabValue}
                      handleTabChange={handleTabChange}
                    />
                  ) : null}
                </>
              )}
              {fetchDreamAvatarsTabsError !== "" ||
              fetchDreamAvatarsStylesError !== "" ? (
                <Error
                  errorMessge={
                    fetchDreamAvatarsTabsError || fetchDreamAvatarsStylesError
                  }
                />
              ) : (
                <AvatarsForContainer id="select_styles">
                  {dreamAvatarsStylesList?.map((item: any, index: number) => (
                    <AvatarsForCard
                      key={item?.image}
                      item={item}
                      checkbox
                      labelPosition="bottom"
                      showPriceBanner
                      handleClick={() => handleSelectStyleCheckbox(item, index)}
                    />
                  ))}
                </AvatarsForContainer>
              )}
            </Box>
          </Box>
        )}
      </LeftSection>
      <RightSection>
        <UploadYourPhotos />
        <Divider color={Theme.COLORS.IRIS_BLUE} sx={{ my: 4, height: "1px" }} />
        <Text>What to expect?</Text>
        <WhatToExpect />
        <Divider color={Theme.COLORS.IRIS_BLUE} sx={{ my: 4, height: "1px" }} />
        <Banner />
        {/* <Cart /> */}
      </RightSection>
      {/* <DreamAvatarsModal
        open={openModal}
        modalTitle="Dream Avatars"
        handleTryNow={handleTryNow}
        handleClose={handleModalClose}
      /> */}
      <SignInModal
        open={!loggedInUser?.idToken && openSignInModal}
        modalTitle="Sign In"
        handleClose={handleSignInModal}
      />
      <CartModal
        open={openCartModal}
        modalTitle="Checkout"
        avatarsFor={avatarsFor}
        disabledBuyNow={disabledBuyNow}
        selectedStyles={selectedStyles}
        paymentInitiated={paymentInitiated}
        handleClose={handleCartModal}
        handlePurchase={handlePurchase}
      />
      <Snackbar
        open={showSnackbar}
        vertical="bottom"
        horizontal="center"
        message={snackbarMessage}
        //@ts-ignore
        severity={snackbarSeverity}
        handleClose={closeSnackbar}
      />
      {selectedStyles?.length ? (
        <FooterButton
          text="Review Order"
          stylesSelectedCount={selectedStyles?.length}
          handleContinue={handleCartModal}
        />
      ) : null}
    </Container>
  );
};

export default Home;

const Container = styled.div`
  padding: 16px;
  display: flex;
  box-sizing: border-box;
  background: ${Theme.COLORS.BG_COLOR};
`;

const LeftSection = styled.div`
  flex: 3;
`;

const AvatarsForContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 32px;
  flex-wrap: wrap;
`;

const RightSection = styled.div`
  flex: 1;
  padding: 16px;
  background: ${Theme.COLORS.BLACK_RUSSIAN};
  margin-left: 16px;
  border-radius: 16px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
`;

const Text = styled.p`
  font-size: 16px;
  font-weight: bold;
  line-height: 1.5;
  margin-bottom: 20px;
  color: ${Theme.COLORS.IRIS_BLUE};
`;
