import { FC, useEffect } from "react";
import styled from "@emotion/styled";
// @ts-ignore
import LazyLoad from "react-lazyload";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import Error from "../../components/Error";
import Header from "../../components/Header";
import CircularLoader from "../../components/CircularLoader";

import { setAvatarsFor } from "../../context/slices/app.slice";
import useGoogleAnalytics from "../../hooks/useGoogleAnalytics";
import useFetchGenerateAvatarsFor from "../../hooks/useFetchGenerateAvatarsFor";

import { fetchGenerateAvatarsForItem } from "../../typings";

import Theme from "../../constants/theme";

interface IGenerateAvatarsForProps {
  user: any;
}

const GenerateAvatarsFor: FC<IGenerateAvatarsForProps> = ({ user }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { sendPageView, sendEvent } = useGoogleAnalytics();

  const {
    fetchingGenerateAvatarsFor,
    generateAvatarsForList,
    generateAvatarsForError,
    fetchGenerateAvatarsFor,
  } = useFetchGenerateAvatarsFor();

  const handleSelectAvatarsFor = (item: any) => {
    const value = item?.name.toLowerCase();
    const eventName = "select_avatars_for_cta";
    const properties = {
      source: "mobile",
      action: "click",
      user: user?.uid,
      value,
    };
    sendEvent(eventName, properties);
    dispatch(setAvatarsFor(item));
    navigate(`/select-style/${value}`);
  };

  const renderFetchGenerateAvatarsForList = () => {
    let Component;
    if (generateAvatarsForError === "") {
      Component = generateAvatarsForList.map(
        (item: fetchGenerateAvatarsForItem) => (
          <Palette
            key={item?.name}
            onClick={() => handleSelectAvatarsFor(item)}
          >
            <Text>{item?.name}</Text>
            <LazyLoad height={110}>
              <Image src={item?.image} alt="avatars" />
            </LazyLoad>
          </Palette>
        )
      );
    } else {
      Component = <Error errorMessge={generateAvatarsForError} />;
    }
    return Component;
  };

  useEffect(() => {
    const properties = {
      action: "pageView",
      page: "generate_avatars_for",
      source: "mobile",
      user: user?.uid,
    };
    sendPageView(properties);
    fetchGenerateAvatarsFor();
  }, []);

  return (
    <Container>
      <Header profile={false} title="Generate Avatars For" />
      {fetchingGenerateAvatarsFor ? (
        <CircularLoader />
      ) : (
        renderFetchGenerateAvatarsForList()
      )}
    </Container>
  );
};

export default GenerateAvatarsFor;

const Container = styled.div`
  width: 100%;
  height: 100vh;
  background: linear-gradient(
    to bottom right,
    ${Theme.COLORS.BG_COLOR},
    ${Theme.COLORS.BLACK_RUSSIAN}
  );
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Palette = styled.div`
  padding: 16px;
  padding-right: 0;
  box-sizing: border-box;
  height: 86px;
  display: flex;
  border-radius: 10px;
  align-items: center;
  width: calc(100% - 32px);
  justify-content: space-between;
  background-color: ${Theme.COLORS.BLACK_RUSSIAN};
  margin-top: 16px;
  border: 2px solid ${Theme.COLORS.ONYX};
  overflow: hidden;
`;

const Text = styled.p`
  font-size: 17px;
  font-weight: 400;
  color: ${Theme.COLORS.TEXT_SECONDARY_COLOR};
`;

const Image = styled.img`
  width: 70px;
  height: 86px;
  flex-shrink: 0;
`;
