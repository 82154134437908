import { Box } from "@mui/material";
import TermsAndConditionsPage from "../../Mobile/screens/TermsAndConditions";

const TermsAndConditions = () => {
  return (
    <Box>
      <TermsAndConditionsPage />
    </Box>
  );
};

export default TermsAndConditions;
