import axios from "axios";
import { useDispatch, useSelector } from "react-redux";

import useFetchOrdersList from "./useFetchOrdersList";
import {
  setIsUploadingImages,
  setShowSnackbar,
} from "../context/slices/app.slice";
import { memoizedSelectApp } from "../context/selectors/app.selector";

const useUploadZipFileToS3 = () => {
  const dispatch = useDispatch();

  const { loggedInUser } = useSelector(memoizedSelectApp);

  const { idToken: token, email } = loggedInUser;
  const { updateOrderList } = useFetchOrdersList();

  const uploadZipFileToS3 = async (zipFile: any, txnId: string) => {
    try {
      const url =
        "https://us-central1-mas-lensify.cloudfunctions.net/api/s3/upload-file";
      const formData = new FormData();
      formData.append("file", zipFile, "images.zip");
      formData.append("email", email);
      formData.append("txnId", txnId);

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      };
      await axios.post(url, formData, config);
      updateOrderList(txnId);
    } catch (error: any) {
      console.error("[Error] upload file to S3:", error.response?.status);
      const payload = {
        showSnackbar: true,
        snackbarMessage:
          error.response?.status === 403
            ? "Session Expired. Kindly refresh page /login."
            : "File upload failed. Try after sometime.",
        snackbarSeverity: "error",
      };
      dispatch(setShowSnackbar(payload));
    } finally {
      dispatch(setIsUploadingImages(false));
    }
  };

  return { uploadZipFileToS3 };
};

export default useUploadZipFileToS3;
