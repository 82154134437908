import { FC } from "react";
import styled from "@emotion/styled";

import Theme from "../../constants/theme";
import { badImagesArray, goodImagesArray } from "../../constants/images";

type TextProps = {
  bold?: boolean;
  color?: string;
};

interface IWhatToExpectProps {}

const WhatToExpect: FC<IWhatToExpectProps> = () => {
  return (
    <Container>
      <Text>
        The type of AI we utilise for Dream Avatars may generate artefacts,
        inaccuracies and defects in the output images (rarely).
        <br />
        <br />
        It is out of our control. So kindly acknowledge and accept that risk
        before continue.
      </Text>
      <Text>
        The more variations you get, the better chances for the great result!
      </Text>
      <Text bold color={Theme.COLORS.IRIS_BLUE}>
        Good Examples
      </Text>
      <ImageContainer>
        {goodImagesArray.map((item) => (
          <Image key={item.id} src={item.image} alt="bad-image" />
        ))}
      </ImageContainer>
      <br />
      <Text bold color={Theme.COLORS.IRIS_BLUE}>
        Bad Examples
      </Text>
      <ImageContainer>
        {badImagesArray.map((item) => (
          <Image key={item.id} src={item.image} alt="bad-image" />
        ))}
      </ImageContainer>
    </Container>
  );
};

export default WhatToExpect;

const Container = styled.div`
  box-sizing: border-box;
`;

const Text = styled.p<TextProps>`
  line-height: 1.5;
  margin-top: 0;
  margin-bottom: 20px;
  font-size: 16px;
  font-weight: ${(props) => (props.bold ? "bold" : "normal")};
  color: ${(props) => props.color || Theme.COLORS.TEXT_SECONDARY_COLOR};
`;

const ImageContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: repeat(1, 1fr);
  gap: 8px;
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 10px;
`;
