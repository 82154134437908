import { FC } from "react";
import styled from "@emotion/styled";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

import Theme from "../constants/theme";

interface IInfoProps {
  messge: string;
}

const Info: FC<IInfoProps> = ({ messge }) => {
  const navigate = useNavigate();

  const handlePlaceOrder = () => {
    navigate("/home");
  };

  return (
    <Container>
      <ErrorText>{messge}</ErrorText>
      <Button
        variant="contained"
        sx={{
          mt: 2,
          height: 50,
          fontWeight: 800,
          background: Theme.COLORS.IRIS_BLUE,
          color: Theme.COLORS.TEXT_PRIMARY_COLOR,
        }}
        onClick={handlePlaceOrder}
      >
        Place Order
      </Button>
    </Container>
  );
};

export default Info;

const Container = styled.div`
  margin-top: 10px;
  padding: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  flex-direction: column;
  background: none;
`;

const ErrorText = styled.p`
  margin: 0;
  font-size: 18px;
  font-weight: bold;
  color: ${Theme.COLORS.IRIS_BLUE};
`;

// const ErrorSubtext = styled.p`
//   margin: 8px;
//   font-size: 16px;
//   color: ${Theme.COLORS.ERROR};
// `;
