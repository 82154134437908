import { Button } from "@mui/material";
import { FC, useState } from "react";
import ImageUploadGuideModal from "./ImageUploadGuideModal";
import useGoogleAnalytics from "../hooks/useGoogleAnalytics";
import { isMobileDevice } from "../utils/browser.util";
import { TLoggedInUser } from "../typings";

const isMobile = isMobileDevice();

interface IImageUploadGuideButtonProps {
  loggedInUser: TLoggedInUser;
}

const ImageUploadGuideButton: FC<IImageUploadGuideButtonProps> = ({
  loggedInUser,
}) => {
  const { sendEvent } = useGoogleAnalytics();

  const [modalOpen, setModalOpen] = useState(false);

  const handleClick = () => {
    setModalOpen(true);
    const eventName = "image_upload_guide_cta";
    const properties = {
      source: isMobile ? "mobile" : "desktop",
      action: "click",
      user: loggedInUser?.uid,
      value: "modal_open",
    };
    sendEvent(eventName, properties);
  };
  const handleModal = () => {
    const eventName = "image_upload_guide_cta";
    const properties = {
      source: isMobile ? "mobile" : "desktop",
      action: "click",
      user: loggedInUser?.uid,
      value: "modal_close",
    };
    sendEvent(eventName, properties);
    setModalOpen(false);
  };

  return (
    <>
      <Button onClick={handleClick}>Image Upload Guide</Button>
      <ImageUploadGuideModal
        open={modalOpen}
        modalTitle="Image Upload Guide"
        handleClose={handleModal}
      />
    </>
  );
};

export default ImageUploadGuideButton;
