import { FC } from "react";
import styled from "@emotion/styled";

import Theme from "../../constants/theme";
import { badImagesExample, goodImagesExample } from "../../constants/images";

interface IUploadYourPhotosProps {}

const UploadYourPhotos: FC<IUploadYourPhotosProps> = () => {
  return (
    <Container>
      <Text>Create your AI avatar in simple steps</Text>
      <HeaderText>
        Upload your photo, and watch as our advanced algorithms craft a
        personalized digital representation that captures your unique essence
        effortlessly.
      </HeaderText>
      <SubText>
        Note: Dream Avatars image generation depends completely on the quality
        of the image.
      </SubText>
      <Text>Good photo examples</Text>
      <SubText>
        Clear Photo, Portraits, Same person / photo, Adults, Plain backgrounds,
        Head tilts and angles.
      </SubText>
      <ImageContainer>
        {goodImagesExample.map((item) => (
          <Image key={item.id} src={item.image} alt="good-image" />
        ))}
      </ImageContainer>
      <br />
      <Text>Bad photo examples</Text>
      <SubText>
        Close-up selfies, Open mouth, Intense facial expression, Blur pics,
        Group shots, Full-length, Kids, Covered faces, Animals, Monotonous pics
        and Nudes.
      </SubText>
      <ImageContainer>
        {badImagesExample.map((item) => (
          <Image key={item.id} src={item.image} alt="bad-image" />
        ))}
      </ImageContainer>
    </Container>
  );
};

export default UploadYourPhotos;

const Container = styled.div`
  box-sizing: border-box;
`;

const HeaderText = styled.p`
  line-height: 1.5;
  font-size: 15px;
  margin-bottom: 0;
  color: ${Theme.COLORS.TEXT_SECONDARY_COLOR};
`;

const Text = styled.p`
  line-height: 1.5;
  margin-top: 0;
  margin-bottom: 0;
  font-size: 16px;
  font-weight: bold;
  color: ${Theme.COLORS.IRIS_BLUE};
`;

const SubText = styled.p`
  line-height: 1.5;
  margin-bottom: 20px;
  font-size: 15px;
  color: ${Theme.COLORS.TEXT_SECONDARY_COLOR};
`;

const ImageContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: repeat(1, 1fr);
  gap: 8px;
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  margin: 8;
  borderradius: 10;
  border-radius: 10px;
`;
