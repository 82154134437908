import { FC, useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { makeStyles } from "@mui/styles";
import styled from "@emotion/styled";
import Theme from "../constants/theme";
import { isMobileDevice } from "../utils/browser.util";
import useGoogleAnalytics from "../hooks/useGoogleAnalytics";
import { TLoggedInUser } from "../typings";

const isMobile = isMobileDevice();

interface Image {
  name: string;
  url: string;
}

interface IImagePreviewModalProps {
  jobOutputImages: [];
  loggedInUser: TLoggedInUser;
}

const useStyles = makeStyles((theme) => ({
  thumbnail: {
    width: "60px",
    height: "60px",
    cursor: "pointer",
    marginTop: 14,
    borderRadius: 4,
  },
}));

const ImagePreviewModal: FC<IImagePreviewModalProps> = ({
  jobOutputImages,
  loggedInUser,
}) => {
  const classes = useStyles();

  const { sendEvent } = useGoogleAnalytics();

  const [images, setImages] = useState<Image[]>([]);
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [selectedImage, setSelectedImage] = useState<Image | null>(null);

  const handleOpenDialog = (image: Image) => {
    setSelectedImage(image);
    setOpenDialog(true);
    const eventName = "image_preview_modal";
    const properties = {
      source: isMobile ? "mobile" : "desktop",
      action: "click",
      user: loggedInUser?.uid,
      value: "modal_open",
    };
    sendEvent(eventName, properties);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setSelectedImage(null);
    const eventName = "image_preview_modal";
    const properties = {
      source: isMobile ? "mobile" : "desktop",
      action: "click",
      user: loggedInUser?.uid,
      value: "modal_close",
    };
    sendEvent(eventName, properties);
  };

  const downloadImage = async (originalImage: any) => {
    const eventName = "download_image";
    const properties = {
      source: isMobile ? "mobile" : "desktop",
      action: "click",
      user: loggedInUser?.uid,
    };
    sendEvent(eventName, properties);

    const image = await fetch(originalImage);

    // Split image name
    const nameSplit = originalImage.split("/");
    const duplicateName = nameSplit.pop();

    const imageBlog = await image.blob();
    const imageURL = URL.createObjectURL(imageBlog);
    const link = document.createElement("a");
    link.href = imageURL;
    link.download = "" + duplicateName + "";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const transformImageURLs = (imageURLsArray: []) => {
    return imageURLsArray?.map((item) => {
      const key = Object.keys(item)[0]; // Assuming there's only one key in each object
      return {
        name: key,
        url: item[key],
      };
    });
  };

  useEffect(() => {
    if (jobOutputImages?.length) {
      const transformedArray = transformImageURLs(jobOutputImages);
      setImages(transformedArray);
    }
  }, [jobOutputImages]);

  return (
    <Container>
      <Grid container spacing={2}>
        {images.map((image) => (
          <Grid item xs={5} sm={3} key={image?.url}>
            <img
              src={image?.url}
              alt={image?.name}
              className={classes.thumbnail}
              onClick={() => handleOpenDialog(image)}
            />
          </Grid>
        ))}
      </Grid>

      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        maxWidth="md"
        fullWidth
        sx={{
          background: "rgba(0, 0, 0, 0.6)",
          "& .MuiPaper-root": {
            borderRadius: 4,
          },
          "& .MuiDialogTitle-root": {
            fontSize: 17,
            fontWeight: "bold",
            color: Theme.COLORS.TEXT_SECONDARY_COLOR,
          },
        }}
      >
        <DialogTitle
          sx={{
            background: Theme.COLORS.BG_COLOR,
            color: Theme.COLORS.TEXT_SECONDARY_COLOR,
          }}
        >
          {selectedImage && selectedImage.name}
          <IconButton
            aria-label="close"
            onClick={handleCloseDialog}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: Theme.COLORS.TEXT_SECONDARY_COLOR,
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent
          dividers
          sx={{
            background: Theme.COLORS.BLACK_RUSSIAN,
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
            overflow: "auto",
            "::-webkit-scrollbar": {
              display: "none",
            },
            scrollbarWidth: "none",
          }}
        >
          <Image src={selectedImage?.url} alt={selectedImage?.name} />
        </DialogContent>
        <Footer>
          <Button
            fullWidth
            variant="contained"
            sx={{
              mr: 1,
              height: 50,
              fontWeight: 800,
              borderRadius: 2,
              background: Theme.COLORS.IRIS_BLUE,
              color: Theme.COLORS.TEXT_PRIMARY_COLOR,
            }}
            onClick={() => downloadImage(selectedImage?.url)}
          >
            Download
          </Button>
          {/* <Button fullWidth variant="contained" color="secondary">
            Share
          </Button> */}
        </Footer>
      </Dialog>
    </Container>
  );
};

export default ImagePreviewModal;

const Container = styled.div`
  width: 100%;
  overflow: auto;
  box-sizing: border-box;
`;

const Image = styled.img`
  width: 100%;
  height: auto;
  border-radius: 12px;
  margin-right: 8px;
  cursor: pointer;
`;

const Footer = styled.div`
  width: 100%;
  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  background: ${Theme.COLORS.BLACK_RUSSIAN};
`;
