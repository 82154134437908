import { FC, useEffect, useState } from "react";
import styled from "@emotion/styled";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Button } from "@mui/material";
import GavelIcon from "@mui/icons-material/Gavel";
import LogoutIcon from "@mui/icons-material/Logout";
import PolicyIcon from "@mui/icons-material/Policy";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";

import VideoBanner from "../../components/VideoBanner";
import DropdownModal from "../../components/DropownModal";
import SignInDropdownModal from "../../components/SignInDropdownModal";

import useSetUser from "../../hooks/useSetUser";
import useGoogleSignIn from "../../hooks/useGoogleSignIn";
import useGoogleFirestore from "../../hooks/useGoogleFirestore";
import useGoogleAnalytics from "../../hooks/useGoogleAnalytics";
import { setOpenSignInModal } from "../../context/slices/app.slice";
import { memoizedSelectApp } from "../../context/selectors/app.selector";

import Theme from "../../constants/theme";
import { TitleText, SubText } from "../../constants/styles";
import {
  DUMMY_PROFILE_IMAGE_URL,
  LENSIFY_APP_WALKTHROUGH_MOBILE,
} from "../../constants/images";

interface IHomeProps {
  user: any;
}

const Home: FC<IHomeProps> = ({ user }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { loggedInUser, openSignInModal } = useSelector(memoizedSelectApp);

  const { isSignInViaEmailLink, handleLogout } = useGoogleSignIn();
  const { setLoggedInUser } = useSetUser();
  const { checkUserExists, addNewUser } = useGoogleFirestore();
  const { sendPageView, sendEvent } = useGoogleAnalytics();

  const [dropdownModalOpen, setDropdownModalOpen] = useState(false);

  const handleDreamAvatarsOnClick = () => {
    setDropdownModalOpen(true);
    const eventName = "dream_avatars_cta";
    const properties = {
      source: "mobile",
      action: "click",
      user: loggedInUser?.uid,
    };
    sendEvent(eventName, properties);
  };

  const doesUserExists = async () => {
    const payload = {
      email: user.email,
    };
    const userExits = await checkUserExists(payload, user.accessToken);
    if (!userExits) {
      const data = {
        newUserData: {
          name: user.displayName,
          email: user.email,
          photoURL: user.photoURL,
          uid: user.uid,
          registerDate: new Date().toISOString(),
        },
      };
      addNewUser(data, user.accessToken);
    }
    setLoggedInUser(user);
  };

  const handlePrivacyPolicy = () => {
    navigate("/privacy-policy");
  };

  const handleTermsAndConditions = () => {
    navigate("/terms-and-conditions");
  };

  const handleMyOrders = () => {
    if (loggedInUser?.idToken) {
      navigate("/my-orders");
    } else {
      dispatch(setOpenSignInModal(true));
    }
  };

  const handleSignInModal = () => {
    const eventName = "sign_in_modal";
    const properties = {
      source: "mobile",
      action: "click",
      user: loggedInUser?.uid,
      value: !openSignInModal ? "open" : "close",
    };
    sendEvent(eventName, properties);
    dispatch(setOpenSignInModal(!openSignInModal));
  };

  useEffect(() => {
    if (user) {
      doesUserExists();
    } else {
      isSignInViaEmailLink();
    }
    const properties = {
      action: "pageView",
      page: "home",
      source: "mobile",
      user: user?.uid,
    };
    sendPageView(properties);
  }, [user]);

  return (
    <Container>
      <HeaderContainer>
        <HeaderLeftSection style={{ display: "flex" }}>
          <ProfileImage src={loggedInUser?.photo || DUMMY_PROFILE_IMAGE_URL} />
          <div>
            <HeaderText>Hello</HeaderText>
            <HeaderSubtext>
              {loggedInUser?.name || "Lensify User"}
            </HeaderSubtext>
          </div>
        </HeaderLeftSection>
        <HeaderRightSection>
          <PolicyIcon
            sx={{
              mr: 1.5,
              width: 24,
              height: 24,
              color: Theme.COLORS.IRIS_BLUE,
            }}
            onClick={handlePrivacyPolicy}
          />
          <GavelIcon
            sx={{
              mr: 1.5,
              width: 24,
              height: 24,
              color: Theme.COLORS.IRIS_BLUE,
            }}
            onClick={handleTermsAndConditions}
          />
          <ShoppingCartIcon
            sx={{
              width: 24,
              height: 24,
              color: Theme.COLORS.IRIS_BLUE,
            }}
            onClick={handleMyOrders}
          />
          {loggedInUser?.idToken ? (
            <LogoutIcon
              sx={{
                ml: 1.5,
                width: 24,
                height: 24,
                color: Theme.COLORS.IRIS_BLUE,
              }}
              onClick={handleLogout}
            />
          ) : null}
        </HeaderRightSection>
      </HeaderContainer>
      <VideoContainer>
        <VideoBanner
          videoUrl={LENSIFY_APP_WALKTHROUGH_MOBILE}
          height={240}
          loggedInUser={loggedInUser}
        />
      </VideoContainer>
      <TextContainer>
        <TitleText>
          Craft Your Perfect <br />
          Dream Avatar
        </TitleText>
        <br />
        <SubText>
          Just upload your photos and let
          <br />
          Lensify work its magic.
        </SubText>
      </TextContainer>
      <Button
        fullWidth
        variant="contained"
        sx={{
          fontSize: 16,
          marginTop: 3,
          bottom: "32px",
          padding: "14px",
          borderRadius: 2,
          fontWeight: 800,
          position: "fixed",
          width: "calc(100% - 32px)",
          maxWidth: "704px",
          left: 0,
          right: 0,
          margin: "auto",
          bgcolor: Theme.COLORS.IRIS_BLUE,
          color: Theme.COLORS.TEXT_PRIMARY_COLOR,
        }}
        onClick={handleDreamAvatarsOnClick}
      >
        Dream Avatars
      </Button>
      <DropdownModal
        open={dropdownModalOpen}
        modalTitle="Dream Avatars"
        loggedInUser={loggedInUser}
        setOpen={setDropdownModalOpen}
      />
      <SignInDropdownModal
        open={!loggedInUser?.idToken && openSignInModal}
        modalTitle="Sign In"
        handleClose={handleSignInModal}
      />
    </Container>
  );
};

export default Home;

const Container = styled.div`
  width: 100%;
  height: 100vh;
  background: linear-gradient(
    to bottom right,
    ${Theme.COLORS.BG_COLOR},
    ${Theme.COLORS.BLACK_RUSSIAN}
  );
`;

const HeaderContainer = styled.div`
  display: flex;
  padding: 16px;
  align-items: center;
  justify-content: space-between;
  width: calc(100% - 32px);
  // border-bottom: 2px solid ${Theme.COLORS.SOFT_PINK};
`;

const HeaderLeftSection = styled.div`
  display: flex;
  align-items: center;
`;

const HeaderText = styled.p`
  margin: 0;
  font-size: 14px;
  font-weight: bold;
  color: ${Theme.COLORS.TEXT_SECONDARY_COLOR};
`;

const HeaderSubtext = styled.p`
  margin: 2px 0;
  font-size: 12px;
  font-weight: bold;
  color: ${Theme.COLORS.TEXT_SECONDARY_COLOR};
`;

const HeaderRightSection = styled.div`
  display: flex;
  align-items: center;
`;

const ProfileImage = styled.img`
  width: 32px;
  height: 32px;
  margin-right: 8px;
  border-radius: 4px;
  border: 1.5px solid ${Theme.COLORS.IRIS_BLUE};
`;

const TextContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  // position: absolute;
  // top: 50%;
  // left: 50%;
  // transform: translate(-50%, -50%);
`;

const VideoContainer = styled.div`
  padding: 16px;
  box-sizing: border-box;
`;
