import axios from "axios";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { memoizedSelectApp } from "../context/selectors/app.selector";
import {
  setDreamAvatarsStyles,
  setSelectedStyles,
} from "../context/slices/app.slice";
import useGoogleAnalytics from "./useGoogleAnalytics";
import { isMobileDevice } from "../utils/browser.util";

const isMobile = isMobileDevice();

const useFetchSelectStyle = () => {
  const { loggedInUser, dreamAvatarsStyles } = useSelector(memoizedSelectApp);

  const dispatch = useDispatch();

  const { sendEvent } = useGoogleAnalytics();

  const [fetchingDreamAvatarStyles, setFetchingDreamAvatarStyles] =
    useState(false);
  const [dreamAvatarsStylesList, setDreamAvatarsStylesList] = useState(
    dreamAvatarsStyles || []
  );
  const [fetchDreamAvatarsStylesError, setFetchDreamAvatarsStylesError] =
    useState("");

  const fetchDreamAvatarsStyles = async (avatarsFor: string) => {
    setFetchingDreamAvatarStyles(true);
    setFetchDreamAvatarsStylesError("");
    setDreamAvatarsStylesList([]);
    dispatch(setDreamAvatarsStyles([]));
    dispatch(setSelectedStyles([]));
    try {
      const url =
        "https://us-central1-mas-lensify.cloudfunctions.net/api/dream-avatars/fetch-styles";
      const payload = {
        avatarsFor,
      };
      const response = await axios.post(url, payload);
      setDreamAvatarsStylesList(response.data.styles);
      dispatch(setDreamAvatarsStyles(response.data.styles));
    } catch (error) {
      console.log("[Error] fetch generate avatars for", error);
      setFetchDreamAvatarsStylesError("Something Went Wrong!");
    } finally {
      setFetchingDreamAvatarStyles(false);
    }
  };

  const filterDreamAvatarsStyles = (tab: any) => {
    const tabName = tab?.name.toLowerCase();
    const eventName = "select_styles_tab";
    const properties = {
      source: isMobile ? "mobile" : "desktop",
      action: "click",
      user: loggedInUser?.uid,
      value: tabName,
    };
    sendEvent(eventName, properties);
    if (tabName === "all") {
      setDreamAvatarsStylesList(dreamAvatarsStyles);
    } else {
      const filteredStyles = dreamAvatarsStyles.filter(
        (avatar: any) => avatar?.type === tabName
      );
      setDreamAvatarsStylesList(filteredStyles);
    }
  };

  const updateDreamAvatarsStyles = (index: number) => {
    const updatedDreamAvatarsStyles: any = dreamAvatarsStyles.map(
      (item: any, i: number) =>
        i === index ? { ...item, isSelected: !item.isSelected } : item
    );
    setDreamAvatarsStylesList(updatedDreamAvatarsStyles);
    dispatch(setDreamAvatarsStyles(updatedDreamAvatarsStyles));
  };

  return {
    fetchingDreamAvatarStyles,
    dreamAvatarsStylesList,
    fetchDreamAvatarsStylesError,
    fetchDreamAvatarsStyles,
    filterDreamAvatarsStyles,
    updateDreamAvatarsStyles,
  };
};

export default useFetchSelectStyle;
