import styled from "@emotion/styled";
import { Routes, Route } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";

import Home from "./screens/Home";
import Login from "./screens/Login";
import Purchase from "./screens/Purchase";
import MyOrders from "./screens/MyOrders";
import Onboarding from "./screens/Onboarding";
import SelectStyle from "./screens/SelectStyle";
import WhatToExpect from "./screens/WhatToExpect";
import UploadYourPhotos from "./screens/UploadYourPhotos";
import GenerateAvatarsFor from "./screens/GenerateAvatarsFor";
import PrivacyPolicy from "./screens/PrivacyPolicy";
import TermsAndConditions from "./screens/TermsAndConditions";

import { auth } from "../hooks/useGoogleSignIn";

const MobileRouter = () => {
  const [user] = useAuthState(auth);

  const MobileRouterContainer = styled.div`
    max-width: 768px;
    margin: 0 auto;
  `;

  return (
    <MobileRouterContainer>
      <Routes>
        <Route path="/" element={<Onboarding />} />
        <Route path="/login" element={<Login />} />
        <Route path="/home" element={<Home user={user} />} />
        <Route
          path="/generate-avatars-for"
          element={<GenerateAvatarsFor user={user} />}
        />
        <Route
          path="/select-style/:type"
          element={<SelectStyle user={user} />}
        />
        <Route path="/what-to-expect" element={<WhatToExpect user={user} />} />
        <Route
          path="/upload-your-photos"
          element={<UploadYourPhotos user={user} />}
        />
        <Route path="/purchase" element={<Purchase user={user} />} />
        <Route path="/my-orders" element={<MyOrders user={user} />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
      </Routes>
    </MobileRouterContainer>
  );
};

export default MobileRouter;
