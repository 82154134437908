import { createSelector } from "reselect";
import { RootState } from "../store";

const selectLoggedInUser = (state: RootState) => state.app.loggedInUser;
const selectedStyle = (state: RootState) => state.app.selectedStyle;
const selectedAvatarsFor = (state: RootState) => state.app.avatarsFor;
const selectOrdersList = (state: RootState) => state.app.ordersList;
const selectShowSnackbar = (state: RootState) => state.app.showSnackbar;
const selectSnackbarMessage = (state: RootState) => state.app.snackbarMessage;
const selectSnackbarSeverity = (state: RootState) => state.app.snackbarSeverity;
const selectIsUploadingImages = (state: RootState) =>
  state.app.isUploadingImages;
const selectDreamAvatarsStyles = (state: RootState) =>
  state.app.dreamAvatarsStyles;
const selectSelectedStyles = (state: RootState) => state.app.selectedStyles;
const selectOpenSignInModal = (state: RootState) => state.app.openSignInModal;

export const memoizedSelectApp = createSelector(
  [
    selectLoggedInUser,
    selectedStyle,
    selectedAvatarsFor,
    selectOrdersList,
    selectShowSnackbar,
    selectSnackbarMessage,
    selectSnackbarSeverity,
    selectIsUploadingImages,
    selectDreamAvatarsStyles,
    selectSelectedStyles,
    selectOpenSignInModal,
  ],
  (
    loggedInUser,
    selectedStyle,
    avatarsFor,
    ordersList,
    showSnackbar,
    snackbarMessage,
    snackbarSeverity,
    isUploadingImages,
    dreamAvatarsStyles,
    selectedStyles,
    openSignInModal
  ) => ({
    loggedInUser,
    selectedStyle,
    avatarsFor,
    ordersList,
    showSnackbar,
    snackbarMessage,
    snackbarSeverity,
    isUploadingImages,
    dreamAvatarsStyles,
    selectedStyles,
    openSignInModal,
  })
);
