import axios from "axios";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { memoizedSelectApp } from "../context/selectors/app.selector";
import { setOrdersList, setShowSnackbar } from "../context/slices/app.slice";

const url =
  "https://us-central1-mas-lensify.cloudfunctions.net/api/user/fetch-orders";

const useFetchOrdersList = () => {
  const { ordersList } = useSelector(memoizedSelectApp);
  const dispatch = useDispatch();

  const [fetchingOrderList, setFetchingOrdersList] = useState(false);
  const [fetchingOrdersListError, setFetchingOrdersListError] = useState("");

  const fetchOrdersList = async (email: string, token: string) => {
    setFetchingOrdersList(true);
    dispatch(setOrdersList([]));
    setFetchingOrdersListError("");
    try {
      const payload = {
        email,
      };
      const response = await axios.post(url, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      dispatch(setOrdersList(response.data));
    } catch (error: any) {
      console.log("[Error] fetch orders", error.message);
      setFetchingOrdersListError(error.message);
    } finally {
      setFetchingOrdersList(false);
    }
  };

  const updateOrderList = (txnId: string) => {
    const newOrdersList = JSON.parse(JSON.stringify(ordersList));
    const foundOrder: any = newOrdersList.find(
      (order: any) => order?.transactionId === txnId
    );
    if (foundOrder) {
      foundOrder.jobStatus = "REVIEWING";
      dispatch(setOrdersList(newOrdersList));
      const payload = {
        showSnackbar: true,
        snackbarMessage: "File uploaded successfully",
        snackbarSeverity: "success",
      };
      dispatch(setShowSnackbar(payload));
    }
  };

  return {
    fetchingOrderList,
    ordersList,
    fetchingOrdersListError,
    fetchOrdersList,
    updateOrderList,
  };
};

export default useFetchOrdersList;
