import * as React from "react";
import Tab from "@mui/material/Tab";
import Tabs, { tabsClasses } from "@mui/material/Tabs";
import { styled } from "@mui/material/styles";

import Theme from "../constants/theme";

interface StyledTabsProps {
  children?: React.ReactNode;
  value: number;
  onChange: (event: React.SyntheticEvent, newValue: number) => void;
}

const StyledTabs = styled((props: StyledTabsProps) => (
  <Tabs
    variant="scrollable"
    scrollButtons="auto"
    allowScrollButtonsMobile
    sx={{
      [`& .${tabsClasses.scrollButtons}`]: {
        "&.Mui-disabled": { opacity: 0.3 },
        color: Theme.COLORS.IRIS_BLUE,
      },
    }}
    {...props}
    TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
  />
))({
  "& .MuiTabs-indicator": {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "transparent",
  },
  "& .MuiTabs-indicatorSpan": {
    width: "100%",
    backgroundColor: Theme.COLORS.IRIS_BLUE,
  },
  width: "90%",
  margin: "auto",
  marginTop: 16,
  marginBottom: 0,
});

interface StyledTabProps {
  label: string;
}

const StyledTab = styled((props: StyledTabProps) => (
  <Tab disableRipple {...props} />
))(({ theme }) => ({
  textTransform: "none",
  fontWeight: theme.typography.fontWeightRegular,
  fontSize: theme.typography.pxToRem(15),
  marginRight: theme.spacing(1),
  color: "rgba(255, 255, 255, 0.7)",
  "&.Mui-selected": {
    color: Theme.COLORS.IRIS_BLUE,
  },
  "&.Mui-focusVisible": {
    backgroundColor: "rgba(100, 95, 228, 0.32)",
  },
}));

interface IHorizontalTabs {
  dreamAvatarsTabs: any;
  value: number;
  handleTabChange: (event: React.SyntheticEvent, newValue: number) => void;
}

const HorizontalTabs: React.FC<IHorizontalTabs> = ({
  dreamAvatarsTabs,
  value,
  handleTabChange,
}) => {
  return (
    <StyledTabs
      value={value}
      onChange={handleTabChange}
      aria-label="horizontal tabs"
    >
      {dreamAvatarsTabs?.map((tab: any) => (
        <StyledTab key={tab?.name} label={tab?.name} />
      ))}
    </StyledTabs>
  );
};

export default HorizontalTabs;
