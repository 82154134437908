import { FC } from "react";
import { Box, Skeleton } from "@mui/material";

import Theme from "../../constants/theme";

interface IGenerateAvatarsForShimmer {
  length: number;
  horizontalTabs?: boolean;
}

const GenerateAvatarsForShimmer: FC<IGenerateAvatarsForShimmer> = ({
  length,
  horizontalTabs,
}) => {
  return (
    <Box mt={6}>
      <Skeleton
        variant="rectangular"
        animation="wave"
        height={26}
        width="40%"
        sx={{
          bgcolor: Theme.COLORS.BLACK_RUSSIAN,
          boxSizing: "border-box",
          m: "auto",
        }}
      />
      {horizontalTabs && (
        <Skeleton
          variant="rectangular"
          animation="wave"
          height={26}
          width="75%"
          sx={{
            bgcolor: Theme.COLORS.BLACK_RUSSIAN,
            boxSizing: "border-box",
            m: "auto",
            mt: 2,
          }}
        />
      )}
      <Box
        mt="32px"
        sx={{
          display: "flex",
          justifyContent: "center",
          flexWrap: "wrap",
        }}
      >
        {Array.from({ length }).map((_, index) => (
          <Skeleton
            key={index}
            variant="rectangular"
            animation="wave"
            width={208}
            height={262}
            sx={{
              m: 2,
              bgcolor: Theme.COLORS.BLACK_RUSSIAN,
              borderRadius: 4,
              boxSizing: "border-box",
              border: `4px solid ${Theme.COLORS.ONYX}`,
            }}
          />
        ))}
      </Box>
    </Box>
  );
};

export default GenerateAvatarsForShimmer;
