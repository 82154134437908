import { Backdrop, CircularProgress } from "@mui/material";

import Theme from "../constants/theme";

const CircularLoader = () => {
  return (
    <div>
      <Backdrop
        sx={{
          color: Theme.COLORS.WHITE,
          zIndex: 1,
        }}
        open
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
};

export default CircularLoader;
