import styled from "@emotion/styled";
import { Checkbox } from "@mui/material";
import { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import Theme from "../../constants/theme";
import Error from "../../components/Error";
import Header from "../../components/Header";
import BottomButton from "../../components/BottomButton";
import CircularLoader from "../../components/CircularLoader";

import {
  setSelectedStyles,
  setShowSnackbar,
} from "../../context/slices/app.slice";
import useFetchSelectStyle from "../../hooks/useFetchSelectStyle";
import { memoizedSelectApp } from "../../context/selectors/app.selector";

import { dreamAvatarsSelectStylesItem } from "../../typings";
import Snackbar from "../../components/Snackbar";
import useSnackbar from "../../hooks/useSnackbar";
import HorizontalTabs from "../../components/HorizontalTabs";
import useFetchDreamAvatarsTabs from "../../hooks/useFetchDreamAvatarsTabs";
import useGoogleAnalytics from "../../hooks/useGoogleAnalytics";
import PriceBanner from "../../components/PriceBanner";

interface ISelectStyleProps {
  user: any;
}

const SelectStyle: FC<ISelectStyleProps> = ({ user }) => {
  const {
    avatarsFor,
    selectedStyles,
    showSnackbar,
    snackbarMessage,
    snackbarSeverity,
    loggedInUser,
  } = useSelector(memoizedSelectApp);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { closeSnackbar } = useSnackbar();

  const {
    dreamAvatarsTabs,
    fetchDreamAvatarsTabsError,
    fetchDreamAvatarsTabs,
  } = useFetchDreamAvatarsTabs();

  const {
    fetchingDreamAvatarStyles,
    dreamAvatarsStylesList,
    fetchDreamAvatarsStylesError,
    fetchDreamAvatarsStyles,
    updateDreamAvatarsStyles,
    filterDreamAvatarsStyles,
  } = useFetchSelectStyle();

  const { sendPageView, sendEvent } = useGoogleAnalytics();

  const [tabValue, setTabValue] = useState(0);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
    filterDreamAvatarsStyles(dreamAvatarsTabs[newValue]);
  };

  const handleSelectStyleCheckbox = (item: any, index: number) => {
    const eventName = "select_style_checkbox";
    const properties = {
      source: "mobile",
      action: "click",
      user: loggedInUser?.uid,
      value: item?.name,
    };
    sendEvent(eventName, properties);
    const isAlreadySelected = selectedStyles.some(
      (style: any) => style?.image === item?.image
    );
    if (!item?.isSelected) {
      if (!isAlreadySelected && selectedStyles?.length < 5) {
        const updatedStyles = [...selectedStyles, item];
        updateDreamAvatarsStyles(index);
        dispatch(setSelectedStyles(updatedStyles));
      } else {
        const payload = {
          showSnackbar: true,
          snackbarMessage: "Max 5 styles allowed at a time",
          snackbarSeverity: "info",
        };
        dispatch(setShowSnackbar(payload));
      }
    } else {
      if (isAlreadySelected) {
        const updatedStyles = selectedStyles.filter(
          (style: any) => style?.image !== item?.image
        );
        updateDreamAvatarsStyles(index);
        dispatch(setSelectedStyles(updatedStyles));
      }
    }
  };

  const handleContinue = () => {
    const eventName = "select_style_continue_cta";
    const properties = {
      source: "mobile",
      action: "click",
      user: loggedInUser?.uid,
    };
    sendEvent(eventName, properties);
    navigate("/what-to-expect");
  };

  const renderDreamAvatarsStylesList = () => {
    let Component;
    if (
      fetchDreamAvatarsTabsError === "" &&
      fetchDreamAvatarsStylesError === ""
    ) {
      Component = (
        <>
          <HorizontalTabs
            dreamAvatarsTabs={dreamAvatarsTabs}
            value={tabValue}
            handleTabChange={handleTabChange}
          />
          <TeamsContainer>
            {dreamAvatarsStylesList?.map(
              (style: dreamAvatarsSelectStylesItem, index: number) => (
                <TeamDetailsContainer
                  key={style?.image}
                  onClick={() => handleSelectStyleCheckbox(style, index)}
                >
                  <Team>
                    <PriceBanner price={style?.price || 59} />
                    <TeamImage
                      src={style?.image}
                      alt="team-image"
                      loading="lazy"
                    />
                    <Checkbox
                      aria-label="checkbox"
                      checked={!!style?.isSelected}
                      sx={{
                        height: 24,
                        width: 24,
                        position: "absolute",
                        right: 4,
                        top: 4,
                        color: Theme.COLORS.TEXT_SECONDARY_COLOR,
                        "&.Mui-checked": {
                          color: Theme.COLORS.IRIS_BLUE,
                        },
                      }}
                      onChange={(e) => e.stopPropagation()}
                    />
                  </Team>
                  <TeamName>{style?.name}</TeamName>
                </TeamDetailsContainer>
              )
            )}
          </TeamsContainer>
          <BottomButton
            disabled={selectedStyles?.length < 5}
            text="Continue"
            onClick={handleContinue}
          />
          <Snackbar
            open={showSnackbar}
            vertical="bottom"
            horizontal="center"
            message={snackbarMessage}
            //@ts-ignore
            severity={snackbarSeverity}
            handleClose={closeSnackbar}
          />
        </>
      );
    } else {
      Component = (
        <Error
          errorMessge={
            fetchDreamAvatarsTabsError || fetchDreamAvatarsStylesError
          }
        />
      );
    }
    return Component;
  };

  useEffect(() => {
    if (avatarsFor?.name === "") {
      navigate("/home");
    } else {
      const properties = {
        action: "pageView",
        page: "select_style",
        source: "mobile",
        user: user?.uid,
      };
      sendPageView(properties);
      fetchDreamAvatarsTabs();
      fetchDreamAvatarsStyles(avatarsFor?.name.toLowerCase());
    }
  }, [user]);

  return (
    <Container>
      <Header profile={false} title="Select Style" />
      {fetchingDreamAvatarStyles ? (
        <CircularLoader />
      ) : (
        renderDreamAvatarsStylesList()
      )}
    </Container>
  );
};

export default SelectStyle;

const Container = styled.div`
  width: 100%;
  height: 100vh;
  background: linear-gradient(
    to bottom right,
    ${Theme.COLORS.BG_COLOR},
    ${Theme.COLORS.BLACK_RUSSIAN}
  );
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const TeamsContainer = styled.div`
  padding: 32px;
  display: grid;
  overflow-y: auto;
  height: calc(100vh - 240px);
  grid-template-columns: 1fr 1fr;
  grid-template-rows: repeat(5, 1fr);
  gap: 20px;
  margin-bottom: 76px;
`;

const TeamDetailsContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  max-width: 208px;
  max-height: 262px;
`;

const Team = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  border-radius: 16px;
  border: 2px solid ${Theme.COLORS.ONYX};
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
`;

const TeamImage = styled.img`
  width: 100%;
  height: 100%;
  flex-shrink: 0;
  border-radius: 6px;
  margin-bottom: 8px;
  background-size: cover;
`;

const TeamName = styled.p`
  font-size: 14px;
  margin: 6px 0 0 0;
  font-weight: 500;
  text-align: center;
  color: ${Theme.COLORS.TEXT_SECONDARY_COLOR};
`;
