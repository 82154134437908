import styled from "@emotion/styled";
import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Divider,
  ImageList,
  ImageListItem,
  ImageListItemBar,
} from "@mui/material";
import { FC } from "react";
import Theme from "../constants/theme";
import { calculateTotalPrice } from "../utils/price.utils";

interface IMediaCardProps {
  avatarsFor: any;
  selectedStyles: any;
}

const MediaCard: FC<IMediaCardProps> = ({ avatarsFor, selectedStyles }) => {
  return (
    <Card
      sx={{
        maxWidth: "100%",
        // height: 370,
        mt: 1,
        p: 2,
        bgcolor: Theme.COLORS.BLACK_RUSSIAN,
        borderRadius: 2,
        color: Theme.COLORS.TEXT_SECONDARY_COLOR,
      }}
    >
      {/* <CardMedia
        sx={{ height: 200, objectFit: "contain" }}
        image={selectedStyle?.image}
      />
      <CardContent sx={{ bgcolor: selectedStyle?.bgcolor }}>
        <SubText>{selectedStyle?.subtext}</SubText>
        <Box display="flex" alignItems="center">
          <Text>&#x20B9;{selectedStyle?.price || "199/-"}</Text>
          <Price>&#x20B9;399/-</Price>
        </Box>
        <SubText>20 AI Images</SubText>
      </CardContent> */}
      <Text>Avatars For</Text>
      <ImageListItem>
        <Image src={avatarsFor?.image} alt={avatarsFor?.name} loading="lazy" />
        <ImageListItemBar
          position="bottom"
          title={avatarsFor?.name}
          sx={{
            width: 114,
            borderRadius: "0 0 16px 16px",
            bottom: 4,
            color: Theme.COLORS.TEXT_SECONDARY_COLOR,
          }}
        />
      </ImageListItem>
      <Divider color={Theme.COLORS.ONYX} sx={{ height: "1px", my: 2 }} />
      <Text>Selected Styles</Text>
      <Box>
        <ImageList
          variant="masonry"
          cols={5}
          gap={8}
          sx={{
            overflow: "auto",
            "::-webkit-scrollbar": {
              display: "none",
            },
            scrollbarWidth: "none",
          }}
        >
          {selectedStyles.map((item: any) => (
            <ImageListItem key={item?.image}>
              <Image src={item?.image} alt={item?.name} loading="lazy" />
              <ImageListItemBar
                position="bottom"
                title={item?.name}
                sx={{
                  width: 114,
                  borderRadius: "0 0 16px 16px",
                  bottom: 4,
                  color: Theme.COLORS.TEXT_SECONDARY_COLOR,
                }}
              />
            </ImageListItem>
          ))}
        </ImageList>
      </Box>
      <Divider color={Theme.COLORS.ONYX} sx={{ height: "1px", my: 2 }} />
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          boxSizing: "border-box",
        }}
      >
        Order Details:
        <br />
        <br />
        Each style 1 image. Total 5 images.
        <Box
          sx={{
            display: "flex",
            boxSizing: "border-box",
            justifyContent: "space-between",
          }}
        >
          {/* Total: */}
          <Box
            p={1.5}
            border={`2px solid ${Theme.COLORS.ONYX}`}
            borderRadius={2}
          >
            <PriceText>
              &#x20B9;{calculateTotalPrice(selectedStyles) || 295}/-
            </PriceText>
            <Price>&#x20B9;599/-</Price>
          </Box>
          {/* <Price>&#x20B9;599/-</Price> */}
        </Box>
      </Box>
    </Card>
  );
};

export default MediaCard;

const Text = styled.p`
  font-size: 18px;
  font-weight: bold;
  color: ${Theme.COLORS.IRIS_BLUE};
  margin: 0;
  margin-bottom: 16px;
`;

const Image = styled.img`
  width: 110px;
  height: 128px;
  border-radius: 16px;
  border: 2px solid ${Theme.COLORS.ONYX};
`;

const PriceText = styled.p`
  font-size: 18px;
  font-weight: bold;
  color: ${Theme.COLORS.IRIS_BLUE};
  margin: 0;
`;

const Price = styled.p`
  font-size: 14px;
  color: ${Theme.COLORS.SONIC_SILVER};
  text-decoration: line-through;
  margin: 0;
`;
