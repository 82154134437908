import { FC, useEffect } from "react";
import styled from "@emotion/styled";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import Info from "../../components/Info";
import Error from "../../components/Error";
import Header from "../../components/Header";
import Snackbar from "../../components/Snackbar";
import OrderCard from "../../components/OrderCard";
import CircularLoader from "../../components/CircularLoader";

import useSnackbar from "../../hooks/useSnackbar";
import useFetchOrdersList from "../../hooks/useFetchOrdersList";
import { memoizedSelectApp } from "../../context/selectors/app.selector";

import Theme from "../../constants/theme";
import useGoogleAnalytics from "../../hooks/useGoogleAnalytics";

interface IMyOrdersProps {
  user: any;
}

const MyOrders: FC<IMyOrdersProps> = ({ user }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { sendPageView } = useGoogleAnalytics();

  const { loggedInUser, showSnackbar, snackbarMessage, snackbarSeverity } =
    useSelector(memoizedSelectApp);

  const {
    fetchingOrderList,
    ordersList,
    fetchingOrdersListError,
    fetchOrdersList,
  } = useFetchOrdersList();

  const { closeSnackbar } = useSnackbar();

  useEffect(() => {
    if (!user) {
      navigate("/home");
    } else {
      fetchOrdersList(user?.email, user?.accessToken);
      const properties = {
        action: "pageView",
        page: "my_orders",
        source: "mobile",
        user: user?.uid,
      };
      sendPageView(properties);
    }
  }, [user]);

  return (
    <Container>
      <Header profile={false} title="My Orders" />
      {fetchingOrderList ? (
        <CircularLoader />
      ) : fetchingOrdersListError === "" ? (
        ordersList?.length ? (
          ordersList?.map((order: any) => (
            <OrderCard
              key={order?.transactionId}
              order={order}
              loggedInUser={loggedInUser}
            />
          ))
        ) : (
          <Info messge="No Orders Yet!" />
        )
      ) : (
        <Error errorMessge={fetchingOrdersListError} />
      )}
      <Snackbar
        open={showSnackbar}
        vertical="bottom"
        horizontal="center"
        message={snackbarMessage}
        //@ts-ignore
        severity={snackbarSeverity}
        handleClose={closeSnackbar}
      />
    </Container>
  );
};

export default MyOrders;

const Container = styled.div`
  width: 100%;
  // height: 100vh;
  background: linear-gradient(
    to bottom right,
    ${Theme.COLORS.BG_COLOR},
    ${Theme.COLORS.BLACK_RUSSIAN}
  );
  display: flex;
  align-items: center;
  flex-direction: column;
  overflow-y: scroll;
`;
