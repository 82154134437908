import { FC } from "react";
import styled from "@emotion/styled";

import Carousel from "./Carousel";

interface IBannerProps {}

const Banner: FC<IBannerProps> = () => {
  return (
    <BannerConatiner>
      <Carousel />
    </BannerConatiner>
  );
};

export default Banner;

const BannerConatiner = styled.div`
  width: 348px;
  height: 170px;
  margin-top: 20px;
  border-radius: 16px;
  overflow: hidden;
`;
