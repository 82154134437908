import axios from "axios";

const useGoogleFirestore = () => {
  const checkUserExists = async (payload: any, token: string) => {
    try {
      const url =
        "https://us-central1-mas-lensify.cloudfunctions.net/api/user/exists";
      const response = await axios.post(url, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } catch (error: any) {
      console.log("[Error] user exists", error.message);
      return error.message;
    }
  };

  const addNewUser = async (payload: any, token: string) => {
    try {
      const url =
        "https://us-central1-mas-lensify.cloudfunctions.net/api/user/add-new";
      const response = await axios.post(url, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error: any) {
      console.log("[Error] add user", error.message);
      return error.message;
    }
  };

  return { checkUserExists, addNewUser };
};

export default useGoogleFirestore;
