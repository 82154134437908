import { FC, forwardRef } from "react";
import {
  Backdrop,
  Dialog,
  AppBar,
  Toolbar,
  IconButton,
  Slide,
} from "@mui/material";
import styled from "@emotion/styled";
import CloseIcon from "@mui/icons-material/Close";
import { TransitionProps } from "@mui/material/transitions";

import Theme from "../constants/theme";
import { makeStyles } from "@mui/styles";
import Login from "../Mobile/screens/Login";

interface IDropdownModalProps {
  open: boolean;
  modalTitle: string;
  handleClose: () => void;
}

const useStyles = makeStyles({
  dialogPaper: {
    background: `linear-gradient(to bottom right, ${Theme.COLORS.BG_COLOR}, ${Theme.COLORS.BLACK_RUSSIAN})`,
    boxShadow: "none",
    borderRadius: "8px 8px 0 0",
  },
});

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const SignInDropdownModal: FC<IDropdownModalProps> = ({
  open,
  modalTitle,
  handleClose,
}) => {
  const classes = useStyles();

  return (
    <Backdrop
      open={open}
      sx={{ color: Theme.COLORS.TEXT_SECONDARY_COLOR, zIndex: 1 }}
    >
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
        sx={{ top: 70, maxWidth: 768, left: 0, right: 0, margin: "auto" }}
        PaperProps={{
          className: classes.dialogPaper,
        }}
      >
        <AppBar sx={{ position: "relative", bgcolor: Theme.COLORS.BG_COLOR }}>
          <Toolbar>
            <ModalTitle>{modalTitle}</ModalTitle>
            <IconButton
              edge="end"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <Login />
      </Dialog>
    </Backdrop>
  );
};

export default SignInDropdownModal;

const ModalTitle = styled.p`
  flex: 1;
  font-size: 16px;
  font-weight: bold;
  color: ${Theme.COLORS.TEXT_SECONDARY_COLOR};
`;
