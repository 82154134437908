import { FC } from "react";
import { Box } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import ReactPlayer from "react-player/lazy";
import { styled } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";

import Theme from "../constants/theme";
import { IMAGE_UPLOAD_GUIDE_VIDEO_URL } from "../constants/images";
import { isMobileDevice } from "../utils/browser.util";

const isMobile = isMobileDevice();

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiPaper-root": {
    width: isMobile ? "100%" : "33%",
    maxWidth: 800,
    borderRadius: 16,
    background: Theme.COLORS.BLACK_RUSSIAN,
    overflow: "auto",
    "::-webkit-scrollbar": {
      display: "none",
    },
    scrollbarWidth: "none",
    color: Theme.COLORS.LIGHT_GRAY,
  },
  "& .MuiDialogTitle-root": {
    fontSize: 17,
    fontWeight: "bold",
    color: Theme.COLORS.TEXT_SECONDARY_COLOR,
    background: Theme.COLORS.BG_COLOR,
  },
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(2),
  },
}));

interface IImageUploadGuideModalProps {
  open: boolean;
  modalTitle: string;
  handleClose: () => void;
  //   handleTryNow: () => void;
}

const ImageUploadGuideModal: FC<IImageUploadGuideModalProps> = ({
  open,
  modalTitle,
  handleClose,
  //   handleTryNow,
}) => {
  return (
    <BootstrapDialog
      open={open}
      sx={{
        background: "rgba(0, 0, 0, 0.6)",
      }}
      onClose={handleClose}
      aria-labelledby="image-upload-guide-title"
    >
      <DialogTitle sx={{ m: 0, p: 2 }} id="image-upload-guide-title">
        {modalTitle}
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: Theme.COLORS.WHITE,
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent dividers>
        <Box
          sx={{
            height: "60vh",
          }}
        >
          <ReactPlayer
            width="100%"
            height="100%"
            url={IMAGE_UPLOAD_GUIDE_VIDEO_URL}
            playing={open}
            controls={true}
            loop
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          autoFocus
          fullWidth
          variant="contained"
          sx={{
            height: 50,
            fontWeight: 800,
            fontSize: 16,
            borderRadius: 2,
            background: Theme.COLORS.IRIS_BLUE,
            color: Theme.COLORS.TEXT_PRIMARY_COLOR,
          }}
          onClick={handleClose}
        >
          Understood :)
        </Button>
      </DialogActions>
    </BootstrapDialog>
  );
};

export default ImageUploadGuideModal;
