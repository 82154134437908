import { FC, useState } from "react";
import styled from "@emotion/styled";
import { makeStyles } from "@mui/styles";
import GoogleIcon from "@mui/icons-material/Google";
import { Button, Link, Radio, TextField, Typography } from "@mui/material";

import Theme from "../constants/theme";

const LOGO_URL =
  "https://lensify.masinfionex.com/assets/images/lensify-round-icon.webp";

const useStyles = makeStyles((theme) => ({
  textField: {
    "& label": {
      color: Theme.COLORS.TEXT_SECONDARY_COLOR, // Default label color
    },
    "& label.Mui-focused": {
      color: Theme.COLORS.TEXT_SECONDARY_COLOR, // change label color when focused
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: Theme.COLORS.TEXT_SECONDARY_COLOR, // change border color
      },
      "&:hover fieldset": {
        borderColor: Theme.COLORS.TEXT_SECONDARY_COLOR, // change border color on hover
      },
      "&.Mui-focused fieldset": {
        borderColor: Theme.COLORS.TEXT_SECONDARY_COLOR, // change border color when focused
      },
    },
    "& .MuiInputBase-input": {
      color: Theme.COLORS.TEXT_SECONDARY_COLOR,
    },
    "& .MuiFormHelperText-root": {
      color: Theme.COLORS.TEXT_SECONDARY_COLOR,
    },
  },
}));

interface IInputFormProps {
  helperText: string;
  formFields: any;
  handleOnChange: (value: any) => void;
  emailSignInHandler?: () => void;
  googleSignInHandler?: () => void;
}

const InputForm: FC<IInputFormProps> = ({
  helperText,
  formFields,
  handleOnChange,
  emailSignInHandler,
  googleSignInHandler,
}) => {
  const styles = useStyles();
  const [radioValue, setRadioValue] = useState<boolean>(false);

  const radioOnClickHandler = (e: any) => {
    if (e.target.value === radioValue) {
      setRadioValue(false);
    } else {
      setRadioValue(e.target.value);
    }
  };

  return (
    <Container>
      <Logo src={LOGO_URL} alt="logo" />
      <Text>Lensify</Text>
      <SubText>Futuristic AI Art Generator</SubText>
      <FormContainer>
        {formFields.map((item: any) => (
          <TextField
            fullWidth
            key={item.label}
            label={item.label}
            error={item.error} // Need to handle this in useState
            required={item.required}
            helperText={helperText}
            className={styles.textField}
            inputProps={{
              style: { color: Theme.COLORS.TEXT_SECONDARY_COLOR },
            }}
            sx={{ marginTop: 2 }}
            onChange={(e) => handleOnChange(e)}
          />
        ))}
        <Button
          fullWidth
          variant="contained"
          sx={{
            padding: "14px",
            fontWeight: 800,
            fontSize: 16,
            borderRadius: 2,
            marginTop: 3,
            bgcolor: Theme.COLORS.IRIS_BLUE,
            color: Theme.COLORS.TEXT_PRIMARY_COLOR,
          }}
          onClick={emailSignInHandler}
        >
          Sign In
        </Button>
      </FormContainer>
      <Typography
        variant="subtitle2"
        color={Theme.COLORS.TEXT_SECONDARY_COLOR}
        sx={{ marginTop: 2, marginBottom: 2, fontWeight: "bold" }}
      >
        ---------- OR ----------
      </Typography>
      <Button
        variant="contained"
        startIcon={<GoogleIcon />}
        sx={{
          fontSize: 16,
          padding: "14px",
          borderRadius: 2,
          fontWeight: 800,
          width: "calc(100% - 64px)",
          bgcolor: Theme.COLORS.IRIS_BLUE,
          color: Theme.COLORS.TEXT_PRIMARY_COLOR,
        }}
        onClick={googleSignInHandler}
      >
        Google Sign In
      </Button>
      <FormContainer>
        <Typography
          variant="body2"
          color={Theme.COLORS.TEXT_SECONDARY_COLOR}
          align="center"
          mt={2}
        >
          By logging in, you accept our{" "}
          <Link href="/privacy-policy" target="_blank" rel="noopener">
            Privacy Policy
          </Link>{" "}
          and{" "}
          <Link href="/terms-and-conditions" target="_blank" rel="noopener">
            Terms and conditions
          </Link>
          .
        </Typography>
      </FormContainer>
      <ConsentContainer>
        <Radio
          size="small"
          value={true}
          checked={Boolean(radioValue)}
          onClick={radioOnClickHandler}
          name="consent-radio-button"
          sx={{
            marginTop: -1.1,
            color: Theme.COLORS.TEXT_SECONDARY_COLOR,
            "&.Mui-checked": {
              color: Theme.COLORS.TEXT_SECONDARY_COLOR,
            },
          }}
        />
        <ConsentText>
          I consent to receive marketing materials from Lensify about new
          products, features, and offers of Lensify or it's affiliates.
        </ConsentText>
      </ConsentContainer>
    </Container>
  );
};

export default InputForm;

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const Logo = styled.img`
  width: 102px;
  height: 104px;
  margin-bottom: 0;
  margin-top: 20px;
`;

const Text = styled.p`
  color: ${Theme.COLORS.TEXT_SECONDARY_COLOR};
  font-size: 30px;
  text-align: center;
  font-weight: bold;
  margin-bottom: 0;
`;

const SubText = styled.p`
  color: ${Theme.COLORS.TEXT_SECONDARY_COLOR};
  font-size: 18px;
  text-align: center;
  font-weight: 500;
  opacity: 0.9;
  margin: 8px 0 0 0;
`;

const FormContainer = styled.div`
  padding: 0 32px;
  margin-top: 20px;
  width: calc(100% - 64px);
`;

const ConsentContainer = styled.div`
  padding: 0 40px 0 20px;
  display: flex;
  align-items: flex-start;
  margin-top: 20px;
`;

const ConsentText = styled.div`
  font-size: 13.6px;
  line-height: 20px;
  color: ${Theme.COLORS.TEXT_SECONDARY_COLOR};
`;
