import { FC, useState } from "react";
import Button from "@mui/material/Button";
import { CircularProgress } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

import ImagePreviewer from "./ImagePreviewer";

import useImageZipper from "../hooks/useImageZipper";
import { memoizedSelectApp } from "../context/selectors/app.selector";
import {
  setIsUploadingImages,
  setShowSnackbar,
} from "../context/slices/app.slice";

import Theme from "../constants/theme";
import useGoogleAnalytics from "../hooks/useGoogleAnalytics";
import { isMobileDevice } from "../utils/browser.util";
import { TLoggedInUser } from "../typings";

const isMobile = isMobileDevice();

interface IUploadImagesButtonProps {
  txnId: string;
  loggedInUser: TLoggedInUser;
}

const UploadImagesButton: FC<IUploadImagesButtonProps> = ({
  txnId,
  loggedInUser,
}) => {
  const dispatch = useDispatch();

  const { isUploadingImages } = useSelector(memoizedSelectApp);

  const { createZipFile } = useImageZipper();

  const { sendEvent } = useGoogleAnalytics();

  const [selectedImages, setSelectedImages] = useState<any>([]);
  const [imageError, setImageError] = useState(false);

  const handleSelectImages = (event: any) => {
    const eventName = "select_images_cta";
    const properties = {
      source: isMobile ? "mobile" : "desktop",
      action: "click",
      user: loggedInUser?.uid,
    };
    sendEvent(eventName, properties);

    const files = event.target.files;
    const imageFiles = [];
    setSelectedImages([]);
    setImageError(false);

    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const fileName = file.name.toLowerCase();
      const isValidImage =
        file.type.startsWith("image/") && /\.(jpeg|jpg|png)$/.test(fileName);
      // const isWithinLimit = imageFiles.length >= 8 && imageFiles.length <= 10;
      const isWithinSizeLimit = file.size <= 2 * 1024 * 1024; // 2MB in bytes
      if (isValidImage && isWithinSizeLimit) {
        imageFiles.push(file);
      } else {
        const payload = {
          showSnackbar: true,
          snackbarMessage: "",
          snackbarSeverity: "error",
        };
        if (!isValidImage) {
          payload.snackbarMessage = `${fileName} not valid select JPG/PNG image only`;
        }

        if (!isWithinSizeLimit) {
          payload.snackbarMessage = `${fileName} size more than 2 Mb`;
        }
        dispatch(setShowSnackbar(payload));
      }
    }

    const payload = {
      showSnackbar: true,
      snackbarMessage: "",
      snackbarSeverity: "error",
    };

    if (imageFiles.length < 8) {
      setImageError(true);
      payload.snackbarMessage = "Select minimum of 8 images";
      dispatch(setShowSnackbar(payload));
    }
    if (imageFiles.length > 10) {
      setImageError(true);
      payload.snackbarMessage = "Selected more than 10 images";
      dispatch(setShowSnackbar(payload));
    }

    setSelectedImages(imageFiles);
  };

  const handleUploadImages = () => {
    if (selectedImages?.length) {
      const eventName = "upload_images_cta";
      const properties = {
        source: isMobile ? "mobile" : "desktop",
        action: "click",
        user: loggedInUser?.uid,
        value: selectedImages?.length,
      };
      sendEvent(eventName, properties);
      dispatch(setIsUploadingImages(true));
      createZipFile(selectedImages, txnId);
    }
  };

  return (
    <div>
      {!imageError && selectedImages?.length ? (
        <>
          <ImagePreviewer images={selectedImages} />
          <Button
            fullWidth
            variant="contained"
            component="label"
            sx={{
              fontSize: 16,
              marginTop: 1,
              padding: "10px",
              borderRadius: 2,
              fontWeight: 800,
              background: Theme.COLORS.IRIS_BLUE,
              color: Theme.COLORS.TEXT_PRIMARY_COLOR,
            }}
            onClick={handleUploadImages}
          >
            {isUploadingImages ? (
              <CircularProgress size={24} color="inherit" />
            ) : (
              "Upload"
            )}
          </Button>
        </>
      ) : (
        <Button
          fullWidth
          variant="contained"
          component="label"
          sx={{
            fontSize: 16,
            marginTop: 1,
            padding: "10px",
            borderRadius: 2,
            fontWeight: 800,
            background: Theme.COLORS.IRIS_BLUE,
            color: Theme.COLORS.TEXT_PRIMARY_COLOR,
          }}
        >
          <>
            Select 8-10 Images
            <input
              type="file"
              accept="image/*"
              multiple
              hidden
              onChange={handleSelectImages}
            />
          </>
        </Button>
      )}
    </div>
  );
};

export default UploadImagesButton;
