import styled from "@emotion/styled";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import Snackbar from "../../components/Snackbar";
import InputForm from "../../components/InputForm";
import CircularLoader from "../../components/CircularLoader";

import useGoogleSignIn from "../../hooks/useGoogleSignIn";

import Theme from "../../constants/theme";
import { isMobileDevice } from "../../utils/browser.util";

const loginFormFields = [
  {
    id: 1,
    required: true,
    label: "Email",
    error: false,
    icon: false,
  },
];

const isMobile = isMobileDevice();

const Login = () => {
  const navigate = useNavigate();

  const {
    user,
    loading,
    showSnackbar,
    snackbarMessage,
    snackbarSeverity,
    handleCloseSnackbar,
    signInViaEmailLink,
    isSignInViaEmailLink,
    signInWithGooglePopup,
  } = useGoogleSignIn();

  const [email, setEmail] = useState("");

  const [helperText, setHelperText] = useState("");

  const handleOnChange = (e: any) => {
    setEmail(e.target.value);
  };

  const emailSignInHandler = () => {
    signInViaEmailLink(email);
  };

  const googleSignInHandler = () => {
    signInWithGooglePopup();
  };

  useEffect(() => {
    if (user) {
      navigate("/home");
    } else {
      isSignInViaEmailLink();
    }
  }, [user]);

  return (
    <Container>
      {loading ? (
        <CircularLoader />
      ) : (
        <InputForm
          helperText={helperText}
          formFields={loginFormFields}
          handleOnChange={handleOnChange}
          emailSignInHandler={emailSignInHandler}
          googleSignInHandler={googleSignInHandler}
        />
      )}
      <Snackbar
        open={showSnackbar}
        vertical="bottom"
        horizontal="center"
        message={snackbarMessage}
        severity={snackbarSeverity}
        handleClose={handleCloseSnackbar}
      />
    </Container>
  );
};

export default Login;

const Container = styled.div`
  width: 100%;
  height: ${isMobile && "calc(100vh - 0px)"};
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${isMobile &&
  `
  linear-gradient(
    to bottom right,
    ${Theme.COLORS.BG_COLOR},
    ${Theme.COLORS.BLACK_RUSSIAN}
  );`};
`;
