import { FC } from "react";
import {
  Box,
  CardMedia,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  styled,
  tableCellClasses,
} from "@mui/material";
import eStyled from "@emotion/styled";

import useRenderOrderStatus from "../hooks/useRenderOrderStatus";

import Theme from "../constants/theme";
import { TLoggedInUser, dreamAvatarsSelectStylesItem } from "../typings";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: Theme.COLORS.BLACK_RUSSIAN,
    color: Theme.COLORS.IRIS_BLUE,
    fontSize: 16,
    fontWeight: "bold",
    borderBottom: `2px solid ${Theme.COLORS.IRIS_BLUE}`,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

interface IOrdersTableProps {
  loggedInUser: TLoggedInUser;
  ordersList: any;
}

const OrdersTable: FC<IOrdersTableProps> = ({ loggedInUser, ordersList }) => {
  const { renderOrderStatus } = useRenderOrderStatus();
  return (
    <TableContainer
      component={Paper}
      sx={{
        borderRadius: 0,
        height: "100%",
        background: Theme.COLORS.BLACK_RUSSIAN,
      }}
    >
      <Table stickyHeader sx={{ minWidth: 650 }} aria-label="order table">
        <TableHead>
          <TableRow>
            <StyledTableCell width={300}>Selected Styles Image</StyledTableCell>
            <StyledTableCell>Selected Styles</StyledTableCell>
            <StyledTableCell>Payment</StyledTableCell>
            <StyledTableCell>Transaction No</StyledTableCell>
            <StyledTableCell>Status</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody sx={{ backgroundColor: Theme.COLORS.BLACK_RUSSIAN }}>
          {ordersList.map((order: any) => (
            <TableRow key={order?.transactionId}>
              <TableCell sx={{ verticalAlign: "top" }}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  {order?.selectedStyles?.map(
                    (item: dreamAvatarsSelectStylesItem) => (
                      <CardMedia
                        key={item?.image}
                        sx={{
                          height: 20,
                          width: 20,
                          borderRadius: 2,
                          flexShrink: 0,
                          mr: 2,
                          padding: 1,
                          border: `2px solid ${Theme.COLORS.ONYX}`,
                        }}
                        image={item?.image}
                      />
                    )
                  )}
                </Box>
              </TableCell>
              <TableCell sx={{ verticalAlign: "top" }}>
                {order?.selectedStyles?.map(
                  (item: dreamAvatarsSelectStylesItem) => (
                    <Text key={item?.name}>{item?.name}</Text>
                  )
                )}
              </TableCell>
              <TableCell sx={{ verticalAlign: "top" }}>
                <Text>{`Amount: ${order?.amount / 100}`}/-</Text>
                <Text>{order?.responseCode}</Text>
              </TableCell>
              <TableCell sx={{ verticalAlign: "top" }}>
                <Text>{order?.transactionId}</Text>
              </TableCell>
              <TableCell width={300} sx={{ verticalAlign: "top" }}>
                {renderOrderStatus(order, loggedInUser)}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default OrdersTable;

const Text = eStyled.div`
  margin: 0;
  font-size: 15px;
  font-weight: 500;
  line-height: 1.5;
  color: ${Theme.COLORS.TEXT_SECONDARY_COLOR};
`;
