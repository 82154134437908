import { BrowserRouter as Router } from "react-router-dom";
import MobileRouter from "./Mobile/MobileRouter";
import DesktopRouter from "./Desktop/DesktopRouter";

import { isMobileDevice } from "./utils/browser.util";

const App = () => {
  const isMobile = isMobileDevice();

  return <Router>{isMobile ? <MobileRouter /> : <DesktopRouter />}</Router>;
};

export default App;
