import { Box } from "@mui/material";
import PrivacyPolicyPage from "../../Mobile/screens/PrivacyPolicy";

const PrivacyPolicy = () => {
  return (
    <Box>
      <PrivacyPolicyPage />
    </Box>
  );
};

export default PrivacyPolicy;
