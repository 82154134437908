export const goodImagesArray = [
  {
    id: 1,
    image: require("../assets/images/g1.webp"),
  },
  {
    id: 2,
    image: require("../assets/images/g2.webp"),
  },
  {
    id: 3,
    image: require("../assets/images/g3.webp"),
  },
  {
    id: 4,
    image: require("../assets/images/g4.webp"),
  },
  {
    id: 5,
    image: require("../assets/images/g5.webp"),
  },
];

export const badImagesArray = [
  {
    id: 1,
    image: require("../assets/images/w1.webp"),
  },
  {
    id: 2,
    image: require("../assets/images/w2.webp"),
  },
  {
    id: 3,
    image: require("../assets/images/w3.webp"),
  },
  {
    id: 4,
    image: require("../assets/images/w4.webp"),
  },
  {
    id: 5,
    image: require("../assets/images/w5.webp"),
  },
  // {
  //   id: 6,
  //   image: require("../assets/images/w6.webp"),
  // },
];

export const goodImagesExample = [
  {
    id: 1,
    image: require("../assets/images/good-selfie-0.webp"),
  },
  {
    id: 2,
    image: require("../assets/images/good-selfie-1.webp"),
  },
  {
    id: 3,
    image: require("../assets/images/good-selfie-2.webp"),
  },
  {
    id: 4,
    image: require("../assets/images/good-selfie-3.webp"),
  },
  {
    id: 5,
    image: require("../assets/images/good-selfie-4.webp"),
  },
  // {
  //   id: 6,
  //   image: require("../assets/images/good-selfie-5.webp"),
  // },
];

export const badImagesExample = [
  {
    id: 1,
    image: require("../assets/images/bad-selfie-0.webp"),
  },
  {
    id: 2,
    image: require("../assets/images/bad-selfie-1.webp"),
  },
  {
    id: 3,
    image: require("../assets/images/bad-selfie-2.webp"),
  },
  {
    id: 4,
    image: require("../assets/images/bad-selfie-3.webp"),
  },
  {
    id: 5,
    image: require("../assets/images/bad-selfie-4.webp"),
  },
  // {
  //   id: 6,
  //   image: require("../assets/images/bad-selfie-5.webp"),
  // },
];

export const BANNERS = [
  {
    id: 1,
    image: "https://lensify.masinfionex.com/assets/images/banner-1.webp",
  },
  {
    id: 2,
    image: "https://lensify.masinfionex.com/assets/images/banner-2.webp",
  },
];

export const LOGO = require("../assets/images/logo.webp");

export const LENSIFY_APP_WALKTHROUGH_DESKTOP =
  "https://www.youtube.com/watch?v=crfBk3Zlqew";

export const LENSIFY_APP_WALKTHROUGH_MOBILE =
  "https://www.youtube.com/watch?v=ZHaaT2kRccc";

export const DUMMY_PROFILE_IMAGE_URL =
  "https://lensify-user-images.s3.eu-central-1.amazonaws.com/assets/dream-avatars/dummy-profile-pic.webp";

export const PRICE_BANNER = require("../assets/images/price-banner.webp");

export const YOUTUBE_VIDEO_THUMBNAIL =
  "https://lensify-user-images.s3.eu-central-1.amazonaws.com/assets/dream-avatars/how_to_use_dream_avatars_thumbnail.webp";

export const IMAGE_UPLOAD_GUIDE_VIDEO_URL = "https://youtu.be/VIP1DtpwdKM";
