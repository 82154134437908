import { FC } from "react";
import { Button, CircularProgress } from "@mui/material";
import Theme from "../constants/theme";

interface IBottomButtonProps {
  text: any;
  disabled?: boolean;
  paymentInitiated?: boolean;
  onClick: () => void;
}

const BottomButton: FC<IBottomButtonProps> = ({
  disabled,
  text,
  paymentInitiated,
  onClick,
}) => {
  return (
    <Button
      disabled={disabled}
      fullWidth
      variant="contained"
      sx={{
        fontSize: 16,
        marginTop: 3,
        padding: "14px",
        borderRadius: "16px 16px 0 0",
        fontWeight: 800,
        position: "fixed",
        maxWidth: "704px",
        left: 0,
        right: 0,
        bottom: 0,
        height: 60,
        margin: "auto",
        bgcolor: Theme.COLORS.IRIS_BLUE,
        color: Theme.COLORS.TEXT_PRIMARY_COLOR,
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.75)",
      }}
      onClick={onClick}
    >
      {paymentInitiated ? <CircularProgress size={26} /> : text}
    </Button>
  );
};

export default BottomButton;
