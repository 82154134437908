import styled from "@emotion/styled";
import { Box } from "@mui/material";

import ImagePreviewModal from "../components/ImagePreviewModal";
import UploadImagesButton from "../components/UploadImagesButton";

import { isMobileDevice } from "../utils/browser.util";

import Theme from "../constants/theme";
import ImageUploadGuideButton from "../components/ImageUploadGuideButton";
import { TLoggedInUser } from "../typings";

type TextProps = {
  bold?: boolean;
  color?: string;
};

const isMobile = isMobileDevice();

const useRenderOrderStatus = () => {
  const renderOrderStatus = (order: any, loggedInUser: TLoggedInUser) => {
    if (order?.state === "COMPLETED") {
      const statusMap: { [key: string]: JSX.Element } = {
        UPLOAD_PHOTOS: (
          <Box>
            <Text bold color={Theme.COLORS.INFO}>
              {`Order Status: Stage 1`}
              <Text>
                Upload Photos{" "}
                <ImageUploadGuideButton loggedInUser={loggedInUser} />
              </Text>
            </Text>
            <UploadImagesButton
              txnId={order?.transactionId}
              loggedInUser={loggedInUser}
            />
          </Box>
        ),
        REVIEWING: (
          <Box>
            <Text
              bold
              color={Theme.COLORS.INFO}
            >{`Order Status: Stage 2`}</Text>
            <Text>{`We are reviewing your images`}</Text>
          </Box>
        ),
        PROCESSING: (
          <Box>
            <Text
              bold
              color={Theme.COLORS.WARNING}
            >{`Order Status: Stage 3`}</Text>
            <Text>{`We are processing your request.`}</Text>
          </Box>
        ),
        COMPLETED: (
          <Box>
            <Text
              bold
              color={Theme.COLORS.INFO}
            >{`Order Status: Stage 4`}</Text>
            <Text>{`Hurray! Job almost done.`}</Text>
          </Box>
        ),
        DONE: (
          <Box>
            <Text
              bold
              color={Theme.COLORS.SUCCESS}
            >{`Order Status: Done`}</Text>
            <ImagePreviewModal
              jobOutputImages={order?.jobOutputImages}
              loggedInUser={loggedInUser}
            />
          </Box>
        ),
        ERROR: (
          <Box>
            <Text bold color={Theme.COLORS.ERROR}>
              {`Error: Please refer image upload guide and upload proper images`}
              <ImageUploadGuideButton loggedInUser={loggedInUser} />
            </Text>
            <UploadImagesButton
              txnId={order?.transactionId}
              loggedInUser={loggedInUser}
            />
          </Box>
        ),
      };
      return statusMap[order?.jobStatus] || null;
    }

    if (order?.state === "FAILED") {
      const failedStatusMap: { [key: string]: JSX.Element } = {
        PENDING: (
          <Box>
            <Text
              bold
              color={Theme.COLORS.WARNING}
            >{`Order Status: Payment Pending`}</Text>
            <Text>{`Please allow us 48 hours. We will get back to you on this.`}</Text>
          </Box>
        ),
        ERROR: (
          <Box>
            <Text
              bold
              color={Theme.COLORS.ERROR}
            >{`Order Status: Payment Failed`}</Text>
            <Text>{`In case if it is wrong please drop an email at lensifyai@gmail.com`}</Text>
          </Box>
        ),
      };
      return failedStatusMap[order?.jobStatus] || null;
    }

    return null;
  };

  return { renderOrderStatus };
};

export default useRenderOrderStatus;

const Text = styled.div<TextProps>`
  margin: 0;
  font-size: 15px;
  line-height: 1.5;
  font-weight: ${(props) => (props.bold ? "bold" : 500)};
  color: ${(props) => props.color || Theme.COLORS.TEXT_SECONDARY_COLOR};
  text-align: ${isMobile ? "center" : "left"};
`;
