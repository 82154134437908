// @ts-ignore
import Slider from "react-slick";
import styled from "@emotion/styled";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { BANNERS } from "../constants/images";

const Carousel = () => {
  const settings = {
    fade: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    waitForAnimate: false,
    arrows: false,
    autoplaySpeed: 3000,
  };
  return (
    <SliderContainer>
      <Slider {...settings}>
        {BANNERS.map((banner) => (
          <Slide key={banner.id} src={banner.image} alt="slide" />
        ))}
      </Slider>
    </SliderContainer>
  );
};

export default Carousel;

const SliderContainer = styled.div``;

const Slide = styled.img`
  object-fit: cover;
`;
