import { FC } from "react";
import {
  Box,
  Drawer,
  Link,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import styled from "@emotion/styled";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import HomeIcon from "@mui/icons-material/Home";
import LogoutIcon from "@mui/icons-material/Logout";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";

import useGoogleSignIn from "../hooks/useGoogleSignIn";
import useGoogleAnalytics from "../hooks/useGoogleAnalytics";
import { setOpenSignInModal } from "../context/slices/app.slice";

import Theme from "../constants/theme";
import { DUMMY_PROFILE_IMAGE_URL, LOGO } from "../constants/images";

interface ISideDrawerProps {
  user: any;
}

const SideDrawer: FC<ISideDrawerProps> = ({ user }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const { handleLogout } = useGoogleSignIn();
  const { sendEvent } = useGoogleAnalytics();

  const handleHome = () => {
    const eventName = "side_drawer_tab";
    const properties = {
      source: "desktop",
      action: "click",
      user: user?.uid,
      value: "home_tab",
    };
    sendEvent(eventName, properties);
    navigate("/home");
  };

  const handleMyOrders = () => {
    if (!user?.accessToken) {
      dispatch(setOpenSignInModal(true));
      return;
    }
    const eventName = "side_drawer_tab";
    const properties = {
      source: "desktop",
      action: "click",
      user: user?.uid,
      value: "my_orders_tab",
    };
    sendEvent(eventName, properties);
    navigate("/my-orders");
  };

  const DrawerList = (
    <Box
      sx={{
        width: 220,
        boxSizing: "border-box",
        borderRight: `2px solid ${Theme.COLORS.ONYX}`,
        height: "100%",
      }}
      role="presentation"
    >
      <Box
        p={2}
        sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
      >
        <Logo src={LOGO} alt="Lensify logo" />
      </Box>
      <Box sx={{ p: 2, display: "flex", alignItems: "center" }}>
        <ProfileImage src={user?.photoURL || DUMMY_PROFILE_IMAGE_URL} />
        <div>
          <HeaderText>Hello</HeaderText>
          <HeaderSubtext>{user?.displayName || "Lensify User"}</HeaderSubtext>
        </div>
      </Box>
      <List sx={{ mt: 1 }}>
        <ListItem disablePadding>
          <ListItemButton
            sx={{
              backgroundColor:
                location.pathname === "/home"
                  ? Theme.COLORS.BG_COLOR
                  : "inherit",
            }}
            onClick={handleHome}
          >
            <ListItemIcon>
              <HomeIcon
                sx={{
                  color: Theme.COLORS.TEXT_SECONDARY_COLOR,
                  width: 22,
                  height: 22,
                }}
              />
            </ListItemIcon>
            <ListItemText primary="Home" />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton
            sx={{
              backgroundColor:
                location.pathname === "/my-orders"
                  ? Theme.COLORS.BG_COLOR
                  : "inherit",
            }}
            onClick={handleMyOrders}
          >
            <ListItemIcon>
              <ShoppingCartIcon
                sx={{
                  color: Theme.COLORS.TEXT_SECONDARY_COLOR,
                  width: 22,
                  height: 22,
                }}
              />
            </ListItemIcon>
            <ListItemText primary="My Orders" />
          </ListItemButton>
        </ListItem>
        {user?.accessToken ? (
          <ListItem disablePadding>
            <ListItemButton onClick={handleLogout}>
              <ListItemIcon>
                <LogoutIcon
                  sx={{
                    color: Theme.COLORS.TEXT_SECONDARY_COLOR,
                    width: 22,
                    height: 22,
                  }}
                />
              </ListItemIcon>
              <ListItemText primary="Logout" />
            </ListItemButton>
          </ListItem>
        ) : null}
      </List>
      <Box sx={{ p: 2, position: "fixed", bottom: 0, fontSize: 14 }}>
        <Link
          href="/privacy-policy"
          target="_blank"
          sx={{ display: "block", mt: 1 }}
        >
          Privacy Policy
        </Link>
        <Link
          href="/terms-and-conditions"
          target="_blank"
          sx={{ display: "block", mt: 1 }}
        >
          Terms and Conditions
        </Link>
        <br />
        © 2024 Lensify AI.
        <br />
        All rights reserved.
      </Box>
    </Box>
  );

  return (
    <Drawer
      variant="permanent"
      sx={{
        flexShrink: 0,
        "& .MuiDrawer-paper": {
          color: Theme.COLORS.TEXT_SECONDARY_COLOR,
          background: Theme.COLORS.BLACK_RUSSIAN,
        },
      }}
    >
      {DrawerList}
    </Drawer>
  );
};

export default SideDrawer;

const ProfileImage = styled.img`
  width: 36px;
  height: 36px;
  margin-right: 8px;
  border-radius: 4px;
  border: 2px solid ${Theme.COLORS.IRIS_BLUE};
`;

const HeaderText = styled.p`
  margin: 2px 0;
  font-size: 13px;
  font-weight: bold;
  color: ${Theme.COLORS.TEXT_SECONDARY_COLOR};
`;

const HeaderSubtext = styled.p`
  margin: 3px 0;
  font-size: 12px;
  font-weight: bold;
  color: ${Theme.COLORS.TEXT_SECONDARY_COLOR};
`;

const Logo = styled.img`
  width: 70%;
  height: 70%;
`;
