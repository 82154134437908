import styled from "@emotion/styled";
import { FC, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import Info from "../../components/Info";
import Error from "../../components/Error";
import Snackbar from "../../components/Snackbar";
import OrdersTable from "../../components/OrdersTable";
import CircularLoader from "../../components/CircularLoader";

import useSetUser from "../../hooks/useSetUser";
import useSnackbar from "../../hooks/useSnackbar";
import useFetchOrdersList from "../../hooks/useFetchOrdersList";
import useGoogleAnalytics from "../../hooks/useGoogleAnalytics";
import { memoizedSelectApp } from "../../context/selectors/app.selector";

import Theme from "../../constants/theme";

interface IMyOrdersProps {
  user: any;
}

const MyOrders: FC<IMyOrdersProps> = ({ user }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { showSnackbar, snackbarMessage, snackbarSeverity } =
    useSelector(memoizedSelectApp);

  const {
    fetchingOrderList,
    ordersList,
    fetchingOrdersListError,
    fetchOrdersList,
  } = useFetchOrdersList();

  const { closeSnackbar } = useSnackbar();
  const { setLoggedInUser } = useSetUser();
  const { sendPageView } = useGoogleAnalytics();

  useEffect(() => {
    if (!user) {
      navigate("/home");
    } else {
      // dispatch(resetAppState());
      fetchOrdersList(user?.email, user?.accessToken);
      const properties = {
        action: "pageView",
        page: "my_orders",
        source: "desktop",
        user: user?.uid,
      };
      sendPageView(properties);
      setLoggedInUser(user);
    }
  }, [user]);

  return (
    <Container>
      {fetchingOrderList ? (
        <CircularLoader />
      ) : fetchingOrdersListError === "" ? (
        ordersList?.length ? (
          <OrdersTable loggedInUser={user} ordersList={ordersList} />
        ) : (
          <Info messge="No Orders Yet!" />
        )
      ) : (
        <Error errorMessge={fetchingOrdersListError} />
      )}
      <Snackbar
        open={showSnackbar}
        vertical="bottom"
        horizontal="center"
        message={snackbarMessage}
        //@ts-ignore
        severity={snackbarSeverity}
        handleClose={closeSnackbar}
      />
    </Container>
  );
};

export default MyOrders;

const Container = styled.div`
  padding: 8px;
  height: 100vh;
  box-sizing: border-box;
  background: ${Theme.COLORS.BG_COLOR};
`;
