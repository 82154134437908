import styled from "@emotion/styled";

import OnboardingScreen from "../../Mobile/screens/Onboarding";

import OnboardingPageAnimation from "../../components/OnboardingPageAnimation";

import * as menAnimationData from "../../assets/animation/menAnimation.json";
import * as womenAnimationData from "../../assets/animation/womenAnimation.json";

import Theme from "../../constants/theme";

const Onboarding = () => {
  return (
    <Container>
      <LeftSection>
        <OnboardingPageAnimation animationData={menAnimationData} />
      </LeftSection>
      <MidSection>
        <OnboardingScreen />
      </MidSection>
      <RightSection>
        <OnboardingPageAnimation animationData={womenAnimationData} />
      </RightSection>
    </Container>
  );
};

export default Onboarding;

const Container = styled.div`
  width: 100%;
  height: 100vh;
  background: ${Theme.COLORS.BG_COLOR};
  display: flex;
`;

const LeftSection = styled.div`
  height: 100%;
  flex: 1;
`;

const MidSection = styled.div`
  flex: 1;
`;

const RightSection = styled.div`
  flex: 1;
`;
