import { FC } from "react";
import { Box } from "@mui/material";
import ReactPlayer from "react-player/lazy";

import useGoogleAnalytics from "../hooks/useGoogleAnalytics";
import { isMobileDevice } from "../utils/browser.util";

import { YOUTUBE_VIDEO_THUMBNAIL } from "../constants/images";
import { TLoggedInUser } from "../typings";

const isMobile = isMobileDevice();

interface IVideoBannerProps {
  videoUrl: string;
  loggedInUser: TLoggedInUser;
  height?: string | number;
}

const VideoBanner: FC<IVideoBannerProps> = ({
  videoUrl,
  loggedInUser,
  height,
}) => {
  const { sendEvent } = useGoogleAnalytics();

  const handlePlay = () => {
    const eventName = "lensify_app_walkthrough_video_play";
    const properties = {
      source: isMobile ? "mobile" : "desktop",
      action: "click",
      user: loggedInUser?.uid,
    };
    sendEvent(eventName, properties);
  };

  return (
    <Box
      sx={{
        position: "relative",
        height: height || "100%",
        width: "100%",
      }}
    >
      <ReactPlayer
        width="100%"
        height="100%"
        url={videoUrl}
        playing={true}
        controls={true}
        light={
          <img
            height={height || 308}
            width="100%"
            src={YOUTUBE_VIDEO_THUMBNAIL}
            alt="Thumbnail"
            style={{
              objectFit: "contain",
            }}
          />
        }
        onPlay={handlePlay}
      />
    </Box>
  );
};

export default VideoBanner;
