import { createSlice } from "@reduxjs/toolkit";
import { AppState } from "../../typings";

const initialState: AppState = {
  loggedInUser: {
    uid: "",
    name: "",
    email: "",
    photo: "",
    idToken: "",
  },
  selectedStyle: {
    style: "",
    image: "",
    name: "",
    price: null,
  },
  avatarsFor: {
    name: "",
    image: "",
  },
  ordersList: [],
  showSnackbar: false,
  snackbarMessage: "",
  snackbarSeverity: "info",
  isUploadingImages: false,
  dreamAvatarsStyles: [],
  selectedStyles: [],
  openSignInModal: false,
};

const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    setUser: (state, action) => {
      state.loggedInUser.uid = action.payload.uid;
      state.loggedInUser.name = action.payload.displayName;
      state.loggedInUser.email = action.payload.email;
      state.loggedInUser.photo = action.payload.photoURL;
      state.loggedInUser.idToken = action.payload.accessToken;
    },
    setSelectedStyle: (state, action) => {
      state.selectedStyle = action.payload;
    },
    setAvatarsFor: (state, action) => {
      state.avatarsFor = action.payload;
    },
    setOrdersList: (state, action) => {
      state.ordersList = action.payload;
    },
    setShowSnackbar: (state, action) => {
      state.showSnackbar = action.payload.showSnackbar;
      state.snackbarMessage = action.payload.snackbarMessage;
      state.snackbarSeverity = action.payload.snackbarSeverity;
    },
    setIsUploadingImages: (state, action) => {
      state.isUploadingImages = action.payload;
    },
    setDreamAvatarsStyles: (state, action) => {
      state.dreamAvatarsStyles = action.payload;
    },
    setSelectedStyles: (state, action) => {
      state.selectedStyles = action.payload;
    },
    setOpenSignInModal: (state, action) => {
      state.openSignInModal = action.payload;
    },
    resetAppState: () => {
      return initialState;
    },
  },
});

export const {
  setUser,
  setSelectedStyle,
  setAvatarsFor,
  setOrdersList,
  setShowSnackbar,
  setIsUploadingImages,
  setDreamAvatarsStyles,
  setSelectedStyles,
  setOpenSignInModal,
  resetAppState,
} = appSlice.actions;
export default appSlice.reducer;
