import { useState } from "react";
import { useDispatch } from "react-redux";
import { initializeApp } from "firebase/app";
import { useNavigate } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import {
  getAuth,
  signInWithPopup,
  GoogleAuthProvider,
  sendSignInLinkToEmail,
  isSignInWithEmailLink,
  signInWithEmailLink,
  signOut,
} from "firebase/auth";

import { validateSignUpData } from "../utils/validator.util";
import { resetAppState, setOpenSignInModal } from "../context/slices/app.slice";

import { firebaseConfig } from "../constants/firebaseConfig";

const firebaseApp = initializeApp(firebaseConfig);
export const auth = getAuth(firebaseApp);

const provider = new GoogleAuthProvider();
provider.setCustomParameters({
  prompt: "select_account ",
});

const useGoogleSignIn = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [user, loading, error] = useAuthState(auth);

  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState<
    "error" | "info" | "success" | "warning"
  >("success");

  const handleCloseSnackbar = () => {
    setShowSnackbar(false);
  };

  const signInViaEmailLink = async (email: string) => {
    const user = {
      email,
    };
    const { valid, errors } = validateSignUpData(user);
    if (!valid) {
      setShowSnackbar(true);
      setSnackbarSeverity("error");
      setSnackbarMessage(errors.message);
      return;
    }
    try {
      const actionCodeSettings = {
        url: window.location.href,
        handleCodeInApp: true,
      };
      await sendSignInLinkToEmail(auth, email, actionCodeSettings);
      localStorage.setItem("emailForSignin", email);
      setShowSnackbar(true);
      setSnackbarSeverity("success");
      setSnackbarMessage(
        "Check your email for the login link and sign in with it."
      );
    } catch (error: any) {
      console.log("[Error] sign in", error);
    }
  };

  const signinOnPageLoad = async (email: string) => {
    try {
      await signInWithEmailLink(auth, email, window.location.href);
      localStorage.removeItem("emailForSignin");
      navigate("/home");
    } catch (error: any) {
      setShowSnackbar(true);
      setSnackbarSeverity("error");
      setSnackbarMessage(error.message);
      console.log("[Error] sign in on page load", error.message);
      navigate("/home");
    }
  };

  const isSignInViaEmailLink = () => {
    if (isSignInWithEmailLink(auth, window.location.href)) {
      let email = localStorage.getItem("emailForSignin");
      if (!email) {
        email = window.prompt("Please provide your email");
      }
      try {
        if (email) {
          signinOnPageLoad(email);
        }
      } catch (error: any) {
        console.log("=> [Error] is signin via email link", error.message);
        navigate("/home");
      } finally {
      }
    }
  };

  const signInWithGooglePopup = async () => {
    try {
      await signInWithPopup(auth, provider);
      dispatch(setOpenSignInModal(false));
    } catch (error: any) {
      console.log("[Error] signin with google popup", error.message);
    }
  };

  const handleLogout = () => {
    try {
      signOut(auth);
      dispatch(resetAppState());
      // window.location.reload();
    } catch (error: any) {
      setShowSnackbar(true);
      setSnackbarSeverity("error");
      setSnackbarMessage(error.message);
      localStorage.removeItem("emailForSignin");
      console.log("[Error] sign out", error.message);
    }
  };

  return {
    user,
    loading,
    error,
    showSnackbar,
    snackbarMessage,
    snackbarSeverity,
    handleCloseSnackbar,
    signInViaEmailLink,
    isSignInViaEmailLink,
    signInWithGooglePopup,
    handleLogout,
  };
};

export default useGoogleSignIn;
