import ReactGA from "react-ga4";

type sendPageViewProps = {
  action: string;
  page: string;
  source: string;
  user?: string;
};

type sendEventProps = {
  source: string;
  action: string;
  user?: string;
  label?: string;
  value?: number | string;
};

ReactGA.initialize("G-8528YZGE1R");

const useGoogleAnalytics = () => {
  const sendPageView = (properties: sendPageViewProps) => {
    ReactGA.send(properties);
  };

  const sendEvent = (name: string, properties: sendEventProps) => {
    ReactGA.event(name, properties);
  };

  return { sendPageView, sendEvent };
};

export default useGoogleAnalytics;
