import { FC } from "react";
import styled from "@emotion/styled";
import { Box, CardMedia, Divider } from "@mui/material";

import useRenderOrderStatus from "../hooks/useRenderOrderStatus";

import Theme from "../constants/theme";
import { TLoggedInUser, dreamAvatarsSelectStylesItem } from "../typings";

type TextProps = {
  bold?: boolean;
  color?: string;
};

interface IOrderCardProps {
  order: any;
  loggedInUser: TLoggedInUser;
}

const OrderCard: FC<IOrderCardProps> = ({ order, loggedInUser }) => {
  const { renderOrderStatus } = useRenderOrderStatus();

  const renderOrderHeader = () => {
    let color = Theme.COLORS.SUCCESS;
    const { state, transactionId } = order;
    if (state === "PENDING") {
      color = Theme.COLORS.WARNING;
    }
    if (state === "FAILED") {
      color = Theme.COLORS.ERROR;
    }
    return (
      <OrderNumConatiner
        color={color}
      >{`Txn No. ${transactionId}`}</OrderNumConatiner>
    );
  };

  return (
    <Box
      sx={{
        width: "calc(100% - 16px)",
        bgcolor: Theme.COLORS.BLACK_RUSSIAN,
        my: 1,
        borderRadius: 4,
        boxSizing: "border-box",
        overflow: "hidden",
        boxShadow: "1px 1px 3px rgba(0, 0, 0, 0.3);",
        border: `2px solid ${Theme.COLORS.ONYX}`,
      }}
    >
      {renderOrderHeader()}
      <Box
        sx={{
          p: 1,
          // display: "flex",
          // alignItems: "center",
          // justifyContent: "space-between",
        }}
      >
        <Text>Selected Styles Images</Text>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            // justifyContent: "space-evenly",
          }}
        >
          {order?.selectedStyles?.map((item: dreamAvatarsSelectStylesItem) => (
            <CardMedia
              key={item?.image}
              sx={{
                my: 2,
                height: 20,
                width: 20,
                borderRadius: 2,
                flexShrink: 0,
                mr: 2,
                padding: 1,
                border: `2px solid ${Theme.COLORS.ONYX}`,
              }}
              image={item?.image}
            />
          ))}
        </Box>
        <Box
          height={200}
          sx={{
            alignSelf: "flex-start",
            flex: 1,
            display: "grid",
            gridTemplateColumns: "repeat(2, 1fr)",
            gridTemplateRows: " repeat(3, 1fr)",
            gap: "12px",
          }}
        >
          <Text>Selected Styles</Text>
          <Box
            sx={{
              display: "grid",
              gridTemplate: "1,1",
              gap: "6px",
            }}
          >
            {order?.selectedStyles.map((item: dreamAvatarsSelectStylesItem) => (
              <Text key={item?.name}>{`${item?.name}`}</Text>
            ))}
          </Box>
          <Text>Amount</Text>
          <Text>&#x20B9;{order?.amount / 100}/-</Text>
          <Text>Payment</Text>
          <Text>{order?.responseCode}</Text>
        </Box>
      </Box>
      <Divider color={Theme.COLORS.ONYX} sx={{ height: 2 }} />
      {order?.responseCode === "SUCCESS" ? (
        <Box
          sx={{
            p: 1,
            alignSelf: "flex-start",
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            bgcolor: Theme.COLORS.BLACK_RUSSIAN,
          }}
        >
          {renderOrderStatus(order, loggedInUser)}
        </Box>
      ) : null}
    </Box>
  );
};

export default OrderCard;

const OrderNumConatiner = styled.div<TextProps>`
  background: ${Theme.COLORS.BLACK_RUSSIAN};
  padding: 8px;
  text-align: center;
  font-weight: 600;
  font-size: 14px;
  color: ${(props) => props.color || Theme.COLORS.TEXT_SECONDARY_COLOR};
`;

const Text = styled.div<TextProps>`
  margin: 0;
  font-size: 15px;
  font-weight: ${(props) => (props.bold ? "bold" : 500)};
  color: ${(props) => props.color || Theme.COLORS.TEXT_SECONDARY_COLOR};
`;
